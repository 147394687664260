import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`question/all`, { headers: { secure: 'true' } });
}

export function getQuestionById(id) {
  return axiosService.get(`question/${id}`, { headers: { secure: 'true' } });
}

export function getQuestionPage(page, size, sort, order, filters) {
  return axiosService.get(`question/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createQuestion(questionDetails) {
  return axiosService.post(`question`, questionDetails, { headers: { secure: 'true' } });
}

export function updateQuestion(id, questionDetails) {
  return axiosService.put(`question/${id}`, questionDetails, { headers: { secure: 'true' } });
}

export function deleteQuestion(id) {
  return axiosService.delete(`question/${id}`, { headers: { secure: 'true' } });
}


