import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { updateEmployee, getEmployeeById, getCourses, getAssignedCourses } from '../../services/employee-service';
import { getAll as organizationGetAll } from '../../services/organization-service';
import { getAll as courseGetAll } from '../../services/course-service';

import { parallel } from 'async';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function EmployeeEditor(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const params = useParams();
  const {
    control: employeeFormControl,
    handleSubmit: employeeFormHandler,
    formState: {
      errors: employeeFormErrors
    },
    getValues: employeeFormGetValues,
    setValue: employeeFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      empId: '',
      email: '',
      organization: '',
      courses: [],
      active: false,
      isAdmin: false,
      isTestAccount: false,
      gender: '',
      designation: ''
    }
  });

  const {
    control: coursesFormControl,
    handleSubmit: coursesFormHandler,
    formState: {
      errors: coursesFormErrors
    }
  } = useForm({
    defaultValues: {
      course: '',
      currentSection: '',
      status: '',
      progress: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>
  const [tabValue, setTabValue] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentEmployee, setCurrentEmployee] = useState({
    name: '',
    empId: '',
    email: '',
    organization: '',
    courses: [],
    active: false,
    isTestAccount: false,
    designation: ''
  });
  const [organizations, setOrganizations] = useState([]);
  const [courses, setCourses] = useState([]);
  const [currentCourses, setCurrentCourses] = useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const onSubmit = (formData) => {
    formData.courses = currentCourses;
    setWorkInProgress(true);
    updateEmployee(params.id, formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Employee details were updated successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while updating details of the Employee. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/employees', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }

  const onDeleteCoursesConfirmation = (confirmation) => {
    if (confirmation) {
      setCurrentCourses(currentCourses.filter((e, i) => i != expendableObjectId));
      setSnackbarMessage('Courses deleted successfully.');
      setShowSnackbar(true);
    }
    setShowDeleteConfirmation('none');
  }

  const deleteCourse = (id) => {
    setExpendableObjectId(id);
    setShowDeleteConfirmation('Course');
  }

  const addCourse = (course) => {
    setCurrentCourses([...currentCourses, course]);
  }


  const getOrganizationById = (ids) => {
    if (Array.isArray(ids)) {
      return organizations.filter(e => ids.includes(e._id)).map(e => e.name).join(', ');
    }
    else {
      return organizations.filter(e => e._id === ids).map(e => e.name).join(', ');
    }
  }
  const getCourseById = (ids) => {
    if (Array.isArray(ids)) {
      return courses.filter(e => ids.includes(e._id)).map(e => e.name + e.code).join(', ');
    }
    else {
      return courses.filter(e => e._id === ids).map(e => e.name + e.code).join(', ');
    }
  }

  const fetchDependenciesAndData = () => {
    setWorkInProgress(true);
    parallel({
      organizations: (cb) => {
        organizationGetAll().then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      },
      courses: (cb) => {
        courseGetAll().then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      },
      currentEmployee: (cb) => {
        getEmployeeById(params.id).then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      }
    }, (err, results) => {
      if (err) {
        setWorkInProgress(false);
        setSnackbarMessage('Error occured while fetching data or Employee for id not found. Please contact support.');
        setShowSnackbar(true);
      }
      else {
        setWorkInProgress(false);
        setOrganizations(results.organizations);
        setCourses(results.courses);
        setTimeout(() => {
          setCurrentEmployee(results.currentEmployee, 100);
        });
      }
    });
  }

  React.useEffect(() => {
    fetchDependenciesAndData();
  }, []);

  React.useEffect(() => {
    employeeFormSetValue('name', currentEmployee.name && currentEmployee.name);
    employeeFormSetValue('empId', currentEmployee.empId && currentEmployee.empId);
    employeeFormSetValue('email', currentEmployee.email && currentEmployee.email);
    employeeFormSetValue('organization', currentEmployee.organization && currentEmployee.organization);
    employeeFormSetValue('active', currentEmployee.active && currentEmployee.active);
    employeeFormSetValue('isTestAccount', currentEmployee.isTestAccount && currentEmployee.isTestAccount);
    employeeFormSetValue('gender', currentEmployee.gender && currentEmployee.gender);
    employeeFormSetValue('designation', currentEmployee.designation);
    if (currentEmployee._id) {
      getAssignedCourses(currentEmployee._id).then(response => {
        console.log(response.data.data);
        setCurrentCourses(response.data.data);
      });
    }
    // setCurrentCourses(currentEmployee.courses);
  }, [currentEmployee]);

  return (
    <div>
      {workInProgress ? <Spinner /> : <div></div>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Edit Employee</h2>
        {rights.includes('read-employee') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab> : <span>&nbsp;</span>}
      </div>
      {rights.includes('update-employee') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Employee Details" />
              <Tab label="Courses" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: true }}
                      name="name"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={employeeFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: true }}
                      name="empId"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={employeeFormErrors.empId ? true : false} variant="standard" label="Emp id *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: true }}
                      name="email"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={employeeFormErrors.email ? true : false} variant="standard" label="Email *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: false }}
                      name="gender"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={employeeFormErrors.gender ? true : false} variant="standard" label="Gender" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: false }}
                      name="designation"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={employeeFormErrors.designation ? true : false} variant="standard" label="Designation" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}

                      name="organization"
                      render={({ field }) => <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Organization</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={employeeFormErrors.organization ? true : false} variant="standard" label="Organization" {...field}>{organizations && organizations.length && organizations.map((organization, i) => <MenuItem key={i} value={organization._id}>{organization.name}</MenuItem>)}</Select></FormControl>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: true }}
                      name="active"
                      render={({ field }) => <FormControlLabel label="Active *" style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }} {...field} control={<Switch checked={field.value} {...field} />}></FormControlLabel>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: false }}
                      name="isAdmin"
                      render={({ field }) => <FormControlLabel label="Is Admin" style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }} {...field} control={<Switch checked={field.value} {...field} />}></FormControlLabel>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={employeeFormControl}
                      rules={{ required: false }}
                      name="isTestAccount"
                      render={({ field }) => <FormControlLabel label="Test Account?" style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }} {...field} control={<Switch checked={field.value} {...field} />}></FormControlLabel>} />
                  </div>
                </div>
              </form>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 1}>
            <TableContainer sx={{ my: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Course</TableCell>
                    <TableCell>Current section</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Progress</TableCell>
                    <TableCell style={{ width: 80 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentCourses && currentCourses.map((course, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{course.course._id ? course.course.name + ' ' + course.course.code : courses.filter(e => e._id === course.course)[0].name + ' ' + courses.filter(e => e._id === course.course)[0].name}</TableCell>
                        <TableCell>{course.section}</TableCell>
                        <TableCell>{course.status}</TableCell>
                        <TableCell>{course.progress}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={evt => deleteCourse(i)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={coursesFormControl}
                      rules={{ required: true }}
                      name="course"
                      render={({ field }) => <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-2">Course *</InputLabel><Select id="select-2" labelId="label-2" style={{ width: '100%' }} error={coursesFormErrors.course ? true : false} variant="standard" label="Course *" {...field}>{courses && courses.length && courses.map((course, i) => <MenuItem key={i} value={course._id}>{course.name + ' ' + course.code}</MenuItem>)}</Select></FormControl>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={coursesFormControl}

                      name="currentSection"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={coursesFormErrors.currentSection ? true : false} variant="standard" label="Current section" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={coursesFormControl}

                      name="status"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={coursesFormErrors.status ? true : false} variant="standard" label="Status" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={coursesFormControl}

                      name="progress"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={coursesFormErrors.progress ? true : false} variant="standard" label="Progress" {...field} />} />
                  </div>
                </div>
              </form>
              <div style={{ marginTop: 20 }}>
                <Button variant='contained' color='primary' onClick={coursesFormHandler(addCourse)}>
                  Add Course
                </Button>
              </div>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={employeeFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
        <Confirmation
          title='Delete confirmation'
          message={'Are you sure you want to delete the Course. Once deleted the data cannot be recovered.'}
          open={showDeleteConfirmation === 'Course'}
          handleClose={onDeleteCoursesConfirmation} />
      </div> : <span>You are not authorized to view this page</span>}
    </div>
  )
}