import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';

import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { uploadCourseContentContent, deleteCourseContentContentById, updateCourseContent, getCourseContentById } from '../../services/course-content-service';


import FileUpload from '../../components/file-upload';
import { parallel } from 'async';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function CourseContentEditor(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const params = useParams();
  const {
    control: courseContentFormControl,
    handleSubmit: courseContentFormHandler,
    formState: {
      errors: courseContentFormErrors
    },
    getValues: courseContentFormGetValues,
    setValue: courseContentFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      content: [],
      contentType: '',
      duration: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentCourseContent, setCurrentCourseContent] = useState({
    name: '',
    description: '',
    content: [],
    contentType: ''
  });

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleContentUpload = (file) => {
    setWorkInProgress(true);
    uploadCourseContentContent(file).then(result => {
      setWorkInProgress(false);
      const files = [...courseContentFormGetValues().content];
      files.push(result.data.data[0]);
      courseContentFormSetValue('content', files)
      setSnackbarMessage('Content uploaded successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Content could not be uploaded. Please contact support.');
      setShowSnackbar(true);
    });
  }
  const handleContentDelete = (file) => {
    setWorkInProgress(true);
    deleteCourseContentContentById(file._id).then(result => {
      setWorkInProgress(false);
      const files = courseContentFormGetValues().content.filter(f => f._id != file._id);
      courseContentFormSetValue('content', files);
      setSnackbarMessage('Content deleted successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Content could not be deleted. Please contact support.');
      setShowSnackbar(true);
    });
  }


  const onSubmit = (formData) => {
    setWorkInProgress(true);
    updateCourseContent(params.id, formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Course content details were updated successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while updating details of the Course content. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/course-contents', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }




  React.useEffect(() => {
      getCourseContentById(params.id).then(result => {
        setCurrentCourseContent(result.data.data);
      }).catch(err => {
        setSnackbarMessage('Could not fetch Course content for editing. Please contact support.');
        setShowSnackbar(true);
      })
  }, [])

  React.useEffect(() => {
    courseContentFormSetValue('name', currentCourseContent.name && currentCourseContent.name);
    courseContentFormSetValue('description', currentCourseContent.description && currentCourseContent.description);
    courseContentFormSetValue('content', currentCourseContent.content && currentCourseContent.content);
    courseContentFormSetValue('contentType', currentCourseContent.contentType && currentCourseContent.contentType);
    courseContentFormSetValue('duration', currentCourseContent.duration && currentCourseContent.duration);
  }, [currentCourseContent]);

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>Edit Course content</h2>
        {rights.includes('read-course-content') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('update-course-content') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Course content Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseContentFormControl}
                    
                    name="name"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseContentFormErrors.name ? true : false} variant="standard" label="Name" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseContentFormControl}
                    
                    name="description"
                    render={({ field }) => <TextField multiline rows="7" style={{ width: '100%' }} error={courseContentFormErrors.description ? true : false} variant="standard" label="Description" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseContentFormControl}
                    
                    name="content"
                    render={({ field }) => <FileUpload style={{ width: '100%' }} label="Content" handleDelete={handleContentDelete} handleUpload={handleContentUpload} {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseContentFormControl}
                    rules={{ required: true }}
                    name="contentType"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Content type *</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={courseContentFormErrors.contentType ? true : false} variant="standard" label="Content type *" {...field}><MenuItem value={'Video'} key={0}>Video</MenuItem><MenuItem value={'Image'} key={1}>Image</MenuItem><MenuItem value={'Text'} key={2}>Text</MenuItem></Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseContentFormControl}
                    
                    name="duration"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseContentFormErrors.duration ? true : false} variant="standard" label="Approximate Duration(in mins)" {...field} />}/>
                  </div>  
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={courseContentFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}