import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`course-content/all`, { headers: { secure: 'true' } });
}

export function getCourseContentById(id) {
  return axiosService.get(`course-content/${id}`, { headers: { secure: 'true' } });
}

export function getCourseContentPage(page, size, sort, order, filters) {
  return axiosService.get(`course-content/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createCourseContent(courseContentDetails) {
  return axiosService.post(`course-content`, courseContentDetails, { headers: { secure: 'true' } });
}

export function updateCourseContent(id, courseContentDetails) {
  return axiosService.put(`course-content/${id}`, courseContentDetails, { headers: { secure: 'true' } });
}

export function deleteCourseContent(id) {
  return axiosService.delete(`course-content/${id}`, { headers: { secure: 'true' } });
}

export function uploadCourseContentContent(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`course-content/content`, formData, { headers: { secure: 'true' } });
}

export function getCourseContentContentById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`course-content/content/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteCourseContentContentById(id) {
  return axiosService.delete(`course-content/content/${id}`, { headers: { secure: 'true' } });
}


