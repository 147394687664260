import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`employee/all`, { headers: { secure: 'true' } });
}

export function getEmployeeById(id) {
  return axiosService.get(`employee/${id}`, { headers: { secure: 'true' } });
}

export function getEmployeePage(page, size, sort, order, filters) {
  return axiosService.get(`employee/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createEmployee(employeeDetails) {
  return axiosService.post(`employee`, employeeDetails, { headers: { secure: 'true' } });
}

export function updateEmployee(id, employeeDetails) {
  return axiosService.put(`employee/${id}`, employeeDetails, { headers: { secure: 'true' } });
}

export function deleteEmployee(id) {
  return axiosService.delete(`employee/${id}`, { headers: { secure: 'true' } });
}

export function getCourses() {
  return axiosService.get(`employee/courses`, {
    headers: {
      secure: 'true'
    }
  });
}

export function getAssignedCourses(id) {
  return axiosService.get(`employee/${id}/assigned-courses`, {
    headers: {
      secure: 'true'
    }
  });
}

export function importEmployeeDataFromExcel(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`employee/import`, formData, { headers: { secure: 'true' } });
}
