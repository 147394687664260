import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, IconButton, TablePagination } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { uploadOrganizationLogo, deleteOrganizationLogoById, updateOrganization, getOrganizationById, getCoursesForOrg } from '../../services/organization-service';
import FileUpload from '../../components/file-upload';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import { DeleteForever } from '@mui/icons-material';
import { deleteCourseForOrg } from '../../services/course-organization';
import Confirmation from '../../components/confirmation';

export default function OrganizationEditor(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const params = useParams();
  const {
    control: organizationFormControl,
    handleSubmit: organizationFormHandler,
    formState: {
      errors: organizationFormErrors
    },
    getValues: organizationFormGetValues,
    setValue: organizationFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      logo: [],
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactAddress: '',
      city: '',
      state: '',
      pinCode: ''
    }
  });
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('desc')
  const [pageData, setPageData] = useState({
    data: [],
    count: -1
  })
  const [orgCourse, setOrgCourse] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>
  const [tabValue, setTabValue] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentOrganization, setCurrentOrganization] = useState({
    name: '',
    logo: [],
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactAddress: '',
    city: '',
    state: '',
    pinCode: ''
  });

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleLogoUpload = (file) => {
    setWorkInProgress(true);
    uploadOrganizationLogo(file).then(result => {
      setWorkInProgress(false);
      const files = [...organizationFormGetValues().logo];
      files.push(result.data.data[0]);
      organizationFormSetValue('logo', files)
      setSnackbarMessage('Logo uploaded successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Logo could not be uploaded. Please contact support.');
      setShowSnackbar(true);
    });
  }
  const handleLogoDelete = (file) => {
    setWorkInProgress(true);
    deleteOrganizationLogoById(file._id).then(result => {
      setWorkInProgress(false);
      const files = organizationFormGetValues().logo.filter(f => f._id !== file._id);
      organizationFormSetValue('logo', files);
      setSnackbarMessage('Logo deleted successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Logo could not be deleted. Please contact support.');
      setShowSnackbar(true);
    });
  }

  function updateSortAndOrder(column) {
    if (sort === column) {
      if (order === 'asc') {
        setOrder('desc');
      }
      else {
        setOrder('asc');
      }
    }
    else {
      setOrder('asc');
      setSort(column);
    }
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  const onDeleteCourseForOrg = (orgCourseId) => {
    setWorkInProgress(true);
    setOrgCourse(orgCourseId);
    setShowConfirm(true);
  }

  const onDeleteConfirmation = (response) => {
    setShowConfirm(false);
    if (response) {
      deleteCourseForOrg(params.id, orgCourse).then(result => {
        setWorkInProgress(false);
        getCoursesForOrg(params.id, page, pageSize, sort, order).then(result => {
          if (result.data.data) {
            setPageData({
              data: result.data.data.docs,
              count: result.data.data.totalDocs
            })
          }
        })
        setSnackbarMessage('Course deleted for organization');
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Error occured while deleting course for organization. Please contact support.');
      })

    }
  }

  const onSubmit = (formData) => {
    setWorkInProgress(true);
    updateOrganization(params.id, formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Organization details were updated successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while updating details of the Organization. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/organizations', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }

  React.useEffect(() => {
    getOrganizationById(params.id).then(result => {
      setCurrentOrganization(result.data.data);
    }).catch(err => {
      setSnackbarMessage('Could not fetch Organization for editing. Please contact support.');
      setShowSnackbar(true);
    })
    getCoursesForOrg(params.id, page, pageSize, sort, order).then(result => {
      if (result.data.data) {
        setPageData({
          data: result.data.data.docs,
          count: result.data.data.totalDocs
        })
      }
    })
  }, [params.id, sort, order, page, pageSize])

  React.useEffect(() => {
    organizationFormSetValue('name', currentOrganization.name && currentOrganization.name);
    organizationFormSetValue('logo', currentOrganization.logo && currentOrganization.logo);
    organizationFormSetValue('contactName', currentOrganization.contactName && currentOrganization.contactName);
    organizationFormSetValue('contactEmail', currentOrganization.contactEmail && currentOrganization.contactEmail);
    organizationFormSetValue('contactPhone', currentOrganization.contactPhone && currentOrganization.contactPhone);
    organizationFormSetValue('contactAddress', currentOrganization.contactAddress && currentOrganization.contactAddress);
    organizationFormSetValue('city', currentOrganization.city && currentOrganization.city);
    organizationFormSetValue('state', currentOrganization.state && currentOrganization.state);
    organizationFormSetValue('pinCode', currentOrganization.pinCode && currentOrganization.pinCode);
  }, [currentOrganization, organizationFormSetValue]);

  return (
    <div>
      <Confirmation open={showConfirm} message={`Are you sure you want to disassociate the course from the organization?`} handleClose={onDeleteConfirmation} />
      <Spinner show={workInProgress} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Edit Organization</h2>
        {rights.includes('read-organization') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab> : <span>&nbsp;</span>}
      </div>
      {rights.includes('update-organization') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Organization Details" />
              <Tab label="Courses" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      rules={{ required: true }}
                      name="name"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="logo"
                      render={({ field }) => <FileUpload style={{ width: '100%' }} label="Logo" handleDelete={handleLogoDelete} handleUpload={handleLogoUpload} {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactName"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactName ? true : false} variant="standard" label="Contact name" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactEmail"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactEmail ? true : false} variant="standard" label="Contact email" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactPhone"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactPhone ? true : false} variant="standard" label="Contact Phone" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactAddress"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactAddress ? true : false} variant="standard" label="Contact address" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="city"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.city ? true : false} variant="standard" label="City" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="state"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.state ? true : false} variant="standard" label="State" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="pinCode"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.pinCode ? true : false} variant="standard" label="Pin code" {...field} />} />
                  </div>
                </div>
              </form>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 1}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection={sort === 'name' ? order : false}>
                        <TableSortLabel active={sort === 'name'} direction={sort === 'name' ? order : 'asc'} onClick={evt => updateSortAndOrder('name')}>
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'description' ? order : false}>
                        <TableSortLabel active={sort === 'description'} direction={sort === 'description' ? order : 'asc'} onClick={evt => updateSortAndOrder('description')}>
                          Description
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'level' ? order : false}>
                        <TableSortLabel active={sort === 'level'} direction={sort === 'level' ? order : 'asc'} onClick={evt => updateSortAndOrder('level')}>
                          Level
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'code' ? order : false}>
                        <TableSortLabel active={sort === 'code'} direction={sort === 'code' ? order : 'asc'} onClick={evt => updateSortAndOrder('code')}>
                          Code
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'duration' ? order : false}>
                        <TableSortLabel active={sort === 'duration'} direction={sort === 'duration' ? order : 'asc'} onClick={evt => updateSortAndOrder('duration')}>
                          Duration
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'certificate' ? order : false}>
                        <TableSortLabel active={sort === 'certificate'} direction={sort === 'certificate' ? order : 'asc'} onClick={evt => updateSortAndOrder('certificate')}>
                          Certificate
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sortDirection={sort === 'credits' ? order : false}>
                        <TableSortLabel active={sort === 'credits'} direction={sort === 'credits' ? order : 'asc'} onClick={evt => updateSortAndOrder('credits')}>
                          Credits
                        </TableSortLabel>
                      </TableCell>
                      <TableCell style={{ width: 40, padding: 0 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageData && pageData.data && pageData.data.map((row, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell>{row.course.name}</TableCell>
                          <TableCell>{row.course.description}</TableCell>
                          <TableCell>{row.course.level}</TableCell>
                          <TableCell>{row.course.code}</TableCell>
                          <TableCell>{row.course.duration}</TableCell>
                          <TableCell>{row.course.certificate ? 'true' : 'false'}</TableCell>
                          <TableCell>{row.course.credits}</TableCell>
                          <TableCell className="list-cell" style={{ padding: 0 }}>
                            {rights.includes('delete-course') ? <IconButton onClick={() => onDeleteCourseForOrg(row._id)} color="error" sx={{ margin: 0 }}>
                              <DeleteForever />
                            </IconButton> : <span>&nbsp;</span>}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={pageData.count}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
              </TableContainer>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={organizationFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div> : <span>You are not authorized to view this page</span>}
    </div>
  )
}