import { Button, Fab, Snackbar, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, TablePagination, IconButton, TableSortLabel } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getQuestionPage, deleteQuestion } from '../../services/question-service';

import Confirmation from '../../components/confirmation';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';

export default function QuestionList(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const rights = getRights()
  const [pageData, setPageData] = useState({
    data: [],
    count: -1
  })
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('desc')
  const [filters, setFilters] = useState({})
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [idToDelete, setIdToDelete] = useState(-1);

  const navigate = useNavigate();
  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>

  function showForm(event) {
    navigate('/home/new-question', { replace: false });
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  function editRecord(id) {
    navigate(`/home/edit-question/${id}`, { replace: false })
  }

  function deleteRecord(id) {
    setIdToDelete(id);
    setOpenConfirmation(true);
  }

  function onConfirmationClose(response) {
    setOpenConfirmation(false);
    if (response) {
      setWorkInProgress(true);
      deleteQuestion(idToDelete).then(result => {
        setWorkInProgress(false);
        setSnackbarMessage('Question deleted successfully.');
        setShowSnackbar(true);
        setFilters({});
      }).catch(err => {
        setWorkInProgress(false);
        console.log(err);
        setSnackbarMessage('Question could not be deleted. Please contact support.');
        setShowSnackbar(true);
      });
    }
  }

  function updateSortAndOrder(column) {
    if (sort === column) {
      if (order === 'asc') {
        setOrder('desc');
      }
      else {
        setOrder('asc');
      }
    }
    else {
      setOrder('asc');
      setSort(column);
    }
  }


  useEffect(() => {
    setWorkInProgress(true);
    getQuestionPage(page, pageSize, sort, order, filters).then(result => {
      setWorkInProgress(false);
      if (result.data.data) {
        setPageData({
          data: result.data.data.docs,
          count: result.data.data.totalDocs
        })
      }
    }).catch(err => {
      setWorkInProgress(false);
      setShowSnackbar(true);
      setSnackbarMessage('Error occured while fetching data. Please contact support.');
    })
  }, [ sort, order, page, pageSize, filters ])

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Question List</h2>
        {rights.includes('create-question') ? <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}><Fab size="small" color="secondary" aria-label="add" onClick={showForm}>
          <AddIcon />
        </Fab> <span></span></span> :<span></span> }
      </div>
      {rights.includes('create-question') ? <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
                <TableCell sortDirection={sort === 'questionType' ? order : false}>
                  <TableSortLabel active={sort === 'questionType'} direction={sort === 'questionType' ? order : 'asc'} onClick={evt => updateSortAndOrder('questionType')}>
                  Question type
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'questionText' ? order : false}>
                  <TableSortLabel active={sort === 'questionText'} direction={sort === 'questionText' ? order : 'asc'} onClick={evt => updateSortAndOrder('questionText')}>
                  Question text
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'options' ? order : false}>
                  <TableSortLabel active={sort === 'options'} direction={sort === 'options' ? order : 'asc'} onClick={evt => updateSortAndOrder('options')}>
                  Options
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={sort === 'correctResponses' ? order : false}>
                  <TableSortLabel active={sort === 'correctResponses'} direction={sort === 'correctResponses' ? order : 'asc'} onClick={evt => updateSortAndOrder('correctResponses')}>
                  Correct responses
                  </TableSortLabel>
                </TableCell>
              <TableCell style={{width: 80, padding: 0}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData && pageData.data && pageData.data.map((row, i) => {
              return (
                <TableRow key={i}>
                    <TableCell>{row.questionType}</TableCell>
                    <TableCell>{row.questionText}</TableCell>
                    <TableCell>{row.options}</TableCell>
                    <TableCell>{row.correctResponses}</TableCell>
                  <TableCell className="list-cell" style={{padding: 0}}>
                    {rights.includes('update-question') ? <IconButton color="primary" onClick={evt => editRecord(row._id)} sx={{margin: 0}}>
                      <EditIcon />
                    </IconButton>: <span>&nbsp;</span>}
                    {rights.includes('delete-question') ? <IconButton color="error" onClick={evt => deleteRecord(row._id)} sx={{margin: 0}}>
                      <DeleteForeverIcon />
                    </IconButton>: <span>&nbsp;</span>}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pageData.count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </TableContainer>: <div>You are not authorized to view this page</div>}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
      <Confirmation
        title='Delete confirmation'
        message='Are you sure you want to delete the Question? Once deleted it cannot be recovered.'
        open={openConfirmation}
        handleClose={onConfirmationClose}/>
    </div>
  )
}
