import axiosService from './axios-service';

export function authenticate(userDetails) {
  return axiosService.post('admin-user/auth', userDetails);
}

export function logout() {
  return axiosService.post('admin-user/logout', {}, {
    headers: {
      secure: 'true'
    }
  });
}

export function forgotPassword(forgotPasswordDetails) {
  return axiosService.post('admin-user/forgot-password', forgotPasswordDetails);
}

export function getAdminUserPage(page, size, sort, order) {
  return axiosService.get(`admin-user/page?page=${page}&size=${size}&sort=${sort}&order=${order}`, {
    headers: {
      secure: 'true'
    }
  });
}
export function getAdminUserById(id) {
  return axiosService.get(`admin-user/${id}`, {
    headers: {
      secure: 'true'
    }
  });
}

export function createAdminUser(roleDetails) {
  return axiosService.post(`admin-user`, roleDetails, {
    headers: {
      secure: 'true'
    }
  });
}

export function updateAdminUser(id, roleDetails) {
  return axiosService.put(`admin-user/${id}`, roleDetails, {
    headers: {
      secure: 'true'
    }
  });
}

export function deleteAdminUser(id) {
  return axiosService.delete(`admin-user/${id}`, {
    headers: {
      secure: 'true'
    }
  });
}

export function updatePassword(currentPassword, newPassword) {
  return axiosService.put(`admin-user/update-password`, {
    currentPassword,
    newPassword
  }, {
    headers: {
      secure: 'true'
    }
  });
}