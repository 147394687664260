import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';

import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { updateQuizCandidate, getQuizCandidateById } from '../../services/quiz-candidate-service';
import { getAll as quizGetAll } from '../../services/quiz-service';
import { getAll as organizationGetAll } from '../../services/organization-service';



import { parallel } from 'async';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function QuizCandidateEditor(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const params = useParams();
  const {
    control: quizCandidateFormControl,
    handleSubmit: quizCandidateFormHandler,
    formState: {
      errors: quizCandidateFormErrors
    },
    getValues: quizCandidateFormGetValues,
    setValue: quizCandidateFormSetValue
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      questionnaire: '',
      started: '',
      currentQuestion: '',
      responses: '',
      ended: false,
      organization: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentQuizCandidate, setCurrentQuizCandidate] = useState({
    email: '',
    name: '',
    password: '',
    questionnaire: '',
    started: '',
    currentQuestion: '',
    responses: '',
    ended: false,
    organization: ''
  });
  const [quizzes, setQuizzes] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };



  const onSubmit = (formData) => {
    formData.responses = formData.responses.split('\n');
    setWorkInProgress(true);
    updateQuizCandidate(params.id, formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Quiz candidate details were updated successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while updating details of the Quiz candidate. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/quiz-candidates', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }



  const getQuizById = (ids) => {
    if (Array.isArray(ids)) {
      return quizzes.filter(e => ids.includes(e._id)).map(e => e.name + e.date).join(', ');
    }
    else {
      return quizzes.filter(e => e._id === ids).map(e => e.name + e.date).join(', ');
    }
  }
  const getOrganizationById = (ids) => {
    if (Array.isArray(ids)) {
      return organizations.filter(e => ids.includes(e._id)).map(e => e.name).join(', ');
    }
    else {
      return organizations.filter(e => e._id === ids).map(e => e.name).join(', ');
    }
  }

  const fetchDependenciesAndData = () => {
    setWorkInProgress(true);
    parallel({
      quizzes: (cb) => {
        quizGetAll().then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      },
      organizations: (cb) => {
        organizationGetAll().then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      },
      currentQuizCandidate: (cb) => {
        getQuizCandidateById(params.id).then(result => {
          cb(null, result.data.data);
        }).catch(err => {
          cb(err, null);
        });
      }
    }, (err, results) => {
      if (err) {
        setWorkInProgress(false);
        setSnackbarMessage('Error occured while fetching data or Quiz candidate for id not found. Please contact support.');
        setShowSnackbar(true);
      }
      else {
        setWorkInProgress(false);
        setQuizzes(results.quizzes);
        setOrganizations(results.organizations);
        setTimeout(() => {
          setCurrentQuizCandidate(results.currentQuizCandidate, 100);
        });
      }
    });
  }

  React.useEffect(() => {
    fetchDependenciesAndData();
  }, []);

  React.useEffect(() => {
    quizCandidateFormSetValue('email', currentQuizCandidate.email && currentQuizCandidate.email);
    quizCandidateFormSetValue('name', currentQuizCandidate.name && currentQuizCandidate.name);
    quizCandidateFormSetValue('password', currentQuizCandidate.password && currentQuizCandidate.password);
    quizCandidateFormSetValue('questionnaire', currentQuizCandidate.questionnaire && currentQuizCandidate.questionnaire);
    quizCandidateFormSetValue('started', currentQuizCandidate.started && currentQuizCandidate.started);
    quizCandidateFormSetValue('currentQuestion', currentQuizCandidate.currentQuestion && currentQuizCandidate.currentQuestion);
    quizCandidateFormSetValue('responses', currentQuizCandidate.responses && currentQuizCandidate.responses.join('\n'));
    quizCandidateFormSetValue('ended', currentQuizCandidate.ended && currentQuizCandidate.ended);
    quizCandidateFormSetValue('organization', currentQuizCandidate.organization && currentQuizCandidate.organization);
  }, [currentQuizCandidate]);

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>Edit Quiz candidate</h2>
        {rights.includes('read-quiz-candidate') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('update-quiz-candidate') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Quiz candidate Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="email"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.email ? true : false} variant="standard" label="Email *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="name"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />}/>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="password"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.password ? true : false} variant="standard" label="Password *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="questionnaire"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Questionnaire</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={quizCandidateFormErrors.questionnaire ? true : false} variant="standard" label="Questionnaire" {...field}>{quizzes && quizzes.length && quizzes.map((quiz, i) => <MenuItem key={i} value={quiz._id}>{quiz.name + ' ' + quiz.date}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="started"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.started ? true : false} variant="standard" label="Started" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="currentQuestion"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.currentQuestion ? true : false} variant="standard" label="Current question" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="responses"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={quizCandidateFormErrors.responses ? true : false} variant="standard" label="Responses" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="ended"
                    render={({ field }) => <FormControlLabel label="Ended" style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }} {...field} control={<Switch checked={field.value} {...field} />}></FormControlLabel>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="organization"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-2">Organization</InputLabel><Select id="select-2" labelId="label-2" style={{ width: '100%' }} error={quizCandidateFormErrors.organization ? true : false} variant="standard" label="Organization" {...field}>{organizations && organizations.length && organizations.map((organization, i) => <MenuItem key={i} value={organization._id}>{organization.name}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={quizCandidateFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}