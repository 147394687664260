import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export default function Spinner(props) {
    return (
        <Backdrop open={props.show} sx={{ color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <CircularProgress color='inherit' />
        </Backdrop>
    )
}
