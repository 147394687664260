import axios from 'axios';

const axiosService = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENDPOINT}`
});

axiosService.interceptors.request.use(request => {
  if (request.headers.secure && request.headers.secure === 'true') {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('authToken');
  }
  return request;
}, error => {
  return Promise.reject(error);
});

export default axiosService;