import axiosService from './axios-service';

export function getRolePage(page, size, sort, order) {
  return axiosService.get(`admin-role/page?page=${page}&size=${size}&sort=${sort}&order=${order}`, {
    headers: {
      secure: 'true'
    }
  });
}

export function getRoleById(id) {
  return axiosService.get(`admin-role/${id}`, {
    headers: {
      secure: 'true'
    }
  });
}

export function createRole(roleDetails) {
  return axiosService.post(`admin-role`, roleDetails, {
    headers: {
      secure: 'true'
    }
  });
}

export function updateRole(id, roleDetails) {
  return axiosService.put(`admin-role/${id}`, roleDetails, {
    headers: {
      secure: 'true'
    }
  });
}

export function deleteRole(id) {
  return axiosService.delete(`admin-role/${id}`, {
    headers: {
      secure: 'true'
    }
  });
}