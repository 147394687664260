import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { createQuestion } from '../../services/question-service';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function QuestionForm(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: questionFormControl,
    handleSubmit: questionFormHandler,
    formState: {
      errors: questionFormErrors
    },
    getValues: questionFormGetValues,
    setValue: questionFormSetValue
  } = useForm({
    defaultValues: {
      questionType: '',
      questionText: '',
      options: '',
      correctResponses: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  

  const onSubmit = (formData) => {
    setWorkInProgress(true);
    createQuestion(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Question details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Question. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/questions', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }



  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>New Question</h2>
        {rights.includes('read-question') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('create-question') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Question Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    name="questionType"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Question type</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={questionFormErrors.questionType ? true : false} variant="standard" label="Question type" {...field}><MenuItem value={'OPEN-ENDED'} key={0}>Open Ended</MenuItem><MenuItem value={'YES-NO-PREFER-NOT'} key={1}>Yes/No/Prefer Not to Answer</MenuItem></Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    
                    name="questionText"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionFormErrors.questionText ? true : false} variant="standard" label="Question text" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    
                    name="options"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionFormErrors.options ? true : false} variant="standard" label="Options" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    
                    name="correctResponses"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionFormErrors.correctResponses ? true : false} variant="standard" label="Correct responses" {...field} />}/>
                  </div>  
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={questionFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}