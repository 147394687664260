import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { getAll as getAllOrgs } from '../services/organization-service';
import { getAll as getAllCourses } from '../services/course-service';
import Spinner from '../components/spinner';
import { fullCourseCompletionReport, getDownloadUrl, simpleCourseCompletionReport } from '../services/report-service';
import CourseCompletionReportDetailsDialog from '../components/course-completion-report-details';

const Reports = () => {
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [courses, setCourses] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [downloadHandler, setDownloadHandler] = useState(-1);

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>

  const downloadSimpleCompletionReport = (reportDetails) => {
    setShowSpinner(true);
    setShowReportDialog(false);
    simpleCourseCompletionReport(reportDetails).then(result => {
      setShowSpinner(false);
      document.getElementById('download-link').href = getDownloadUrl(result.data.data.report);
      document.getElementById('download-link').click();
      console.log(result);
    }).catch(err => {
      setShowSpinner(false);
      setShowSnackbar(true)
      setSnackbarMessage(err.response.data.message)
    })
  }
  const downloadFullCompletionReport = (reportDetails) => {
    setShowSpinner(true);
    setShowReportDialog(false);
    fullCourseCompletionReport(reportDetails).then(result => {
      setShowSpinner(false);
      document.getElementById('download-link').href = getDownloadUrl(result.data.data.report);
      document.getElementById('download-link').click();
      console.log(result);
    }).catch(err => {
      setShowSpinner(false);
      setShowSnackbar(true)
      setSnackbarMessage(err.response.data.message)
    })
  }

  useEffect(async () => {
    let orgs = await getAllOrgs();
    let courses = await getAllCourses();
    setOrganizations(orgs.data.data);
    setCourses(courses.data.data);
  }, []);

  return (
    <div>
      <h2>Reports</h2>
      <a href='#' id='download-link' target='_blank' style={{ display: 'none' }}></a>
      <Card sx={{ marginBottom: '30px' }}>
        <CardHeader title='Course completion reports'>
        </CardHeader>
        <CardContent>
          <Typography variant='p'>Course completion reports report employee progress in course for a given organization. The simple report is only to see the progress whereas the detailed report has more data like the repsponse given by the users for the various questions in the course.</Typography>
          <Box sx={{ display: 'flex', gap: '5px', marginTop: '20px' }}>
            <Button variant='contained' onClick={() => {
              setDownloadHandler(0)
              setShowReportDialog(true)
            }}>Simple</Button>
            <Button variant='contained' onClick={() => {
              setDownloadHandler(1)
              setShowReportDialog(true)
            }}>Detailed</Button>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title='Quiz reports'>
        </CardHeader>
        <CardContent>
          <Typography variant='p'>Quiz reports detail the responses and status of employees for quiz for a given organization.</Typography>
          <Box sx={{ display: 'flex', gap: '5px', marginTop: '20px' }}>
            <Button variant='contained'>Completion Report</Button>
          </Box>
        </CardContent>
      </Card>
      <CourseCompletionReportDetailsDialog handlerIndex={downloadHandler} handlers={[downloadSimpleCompletionReport, downloadFullCompletionReport]} show={showReportDialog} onOk={downloadHandler} onClose={() => setShowReportDialog(false)} organizations={organizations} courses={courses} />
      <Spinner show={showSpinner} />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  )
}

export default Reports