import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function Confirmation(props) {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={evt => props.handleClose(true)} autoFocus>Ok</Button>
          <Button variant="contained" autoFocus color="error" onClick={evt => props.handleClose(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
