import './initials.css'

import React from 'react'

const Initials = (props) => {
  const initials = (name) => {
    let result = name?.split(' ').map(part => part.charAt(0)).join('')
    return result
  }

  return (
    <div className='text-center d-flex justify-content-center'>
      <div style={{ borderRadius: '50%', backgroundColor: '#62CDFF', color: '#ffffff', width: 100, height: 100, textAlign: 'center' }}>
        <div style={{ fontSize: '2.5em', paddingTop: '18px' }}>{initials(props.name)}</div>
      </div>
    </div>
  )
}

export default Initials