import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`quiz-candidate/all`, { headers: { secure: 'true' } });
}

export function getQuizCandidateById(id) {
  return axiosService.get(`quiz-candidate/${id}`, { headers: { secure: 'true' } });
}

export function getQuizCandidatePage(page, size, sort, order, filters) {
  return axiosService.get(`quiz-candidate/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createQuizCandidate(quizCandidateDetails) {
  return axiosService.post(`quiz-candidate`, quizCandidateDetails, { headers: { secure: 'true' } });
}

export function updateQuizCandidate(id, quizCandidateDetails) {
  return axiosService.put(`quiz-candidate/${id}`, quizCandidateDetails, { headers: { secure: 'true' } });
}

export function deleteQuizCandidate(id) {
  return axiosService.delete(`quiz-candidate/${id}`, { headers: { secure: 'true' } });
}


export function importQuizCandidateDataFromExcel(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`quiz-candidate/import`, formData, { headers: { secure: 'true' } });
}
