import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { uploadQuizCertificateBackground, deleteQuizCertificateBackgroundById, createQuiz } from '../../services/quiz-service';

import DatePicker from '@mui/lab/DatePicker';
import FileUpload from '../../components/file-upload';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function QuizForm(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: quizFormControl,
    handleSubmit: quizFormHandler,
    formState: {
      errors: quizFormErrors
    },
    getValues: quizFormGetValues,
    setValue: quizFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      certificateLine1: '',
      certificateLine2: '',
      date: new Date(),
      certificateBackground: [],
      nameLocation: '1020,797',
      line1Location: '800,979',
      line2Location: '800,1054',
      dateLocation: '1110,1119',
      fontName: 'Great Vibes',
      nameSize: '3em',
      line1Size: '1.7em',
      line2Size: '1.7em',
      dateSize: '3em'
    }
  });

  const {
    control: questionsFormControl,
    handleSubmit: questionsFormHandler,
    formState: {
      errors: questionsFormErrors
    }
  } = useForm({
    defaultValues: {
      question: '',
      type: '',
      options: '',
      answer: '',
      explanation: '',
      order: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [currentQuestions, setCurrentQuestions] = React.useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

    const handleCertificateBackgroundUpload = (file) => {
      setWorkInProgress(true);
      uploadQuizCertificateBackground(file).then(result => {
        setWorkInProgress(false);
        const files = [...quizFormGetValues().certificateBackground];
        files.push(result.data.data[0]);
        quizFormSetValue('certificateBackground', files)
        setSnackbarMessage('Certificate background uploaded successfully.');
        setShowSnackbar(true);
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Certificate background could not be uploaded. Please contact support.');
        setShowSnackbar(true);
      });
    }
    const handleCertificateBackgroundDelete = (file) => {
      setWorkInProgress(true);
      deleteQuizCertificateBackgroundById(file._id).then(result => {
        setWorkInProgress(false);
        const files = quizFormGetValues().certificateBackground.filter(f => f._id != file._id);
        quizFormSetValue('certificateBackground', files);
        setSnackbarMessage('Certificate background deleted successfully.');
        setShowSnackbar(true);
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Certificate background could not be deleted. Please contact support.');
        setShowSnackbar(true);
      });
    }
  

  const onSubmit = (formData) => {
    formData.questions = currentQuestions;
      formData.questions.forEach(obj => {
        obj.options = obj.options.split('\n');
      });
    setWorkInProgress(true);
    createQuiz(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Quiz details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Quiz. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/quizzes', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }

  const onDeleteQuestionsConfirmation = (confirmation) => {
    if (confirmation) {
      setCurrentQuestions(currentQuestions.filter((e, i) => i != expendableObjectId));
      setSnackbarMessage('Questions deleted successfully.');
      setShowSnackbar(true);
    }
    setShowDeleteConfirmation('none');
  }

  const deleteQuestion = (id) => {
    setExpendableObjectId(id);
    setShowDeleteConfirmation('Question');
  }

  const addQuestion =  (question) => {
    setCurrentQuestions([...currentQuestions, question]);
  }


  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>New Quiz</h2>
        {rights.includes('read-quiz') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('create-quiz') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Quiz Details" />
              <Tab label="Questions" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="name"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="description"
                    render={({ field }) => <TextField multiline rows="8" style={{ width: '100%' }} error={quizFormErrors.description ? true : false} variant="standard" label="Description *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="certificateLine1"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.certificateLine1 ? true : false} variant="standard" label="Certificate line1 *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="certificateLine2"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.certificateLine2 ? true : false} variant="standard" label="Certificate line2 *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="date"
                    render={({ field }) => <FormControl style={{width: '100%'}} sx={{ minWidth: 120 }}><DatePicker value={field.value} onChange={ date => field.onChange(date) } label="Date *" renderInput={(params) => <TextField variant='standard' {...params} />} /></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="certificateBackground"
                    render={({ field }) => <FileUpload style={{ width: '100%' }} label="Certificate background *" handleDelete={handleCertificateBackgroundDelete} handleUpload={handleCertificateBackgroundUpload} {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="nameLocation"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.nameLocation ? true : false} variant="standard" label="Name Location" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="line1Location"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.line1Location ? true : false} variant="standard" label="Line1 Location" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="line2Location"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.line2Location ? true : false} variant="standard" label="Line2 Location" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="dateLocation"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.dateLocation ? true : false} variant="standard" label="Date Location" {...field} />}/>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="nameSize"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.nameSize ? true : false} variant="standard" label="Name Size" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="line1Size"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.line1Size ? true : false} variant="standard" label="Line1 font size" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="line2Size"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.line2Size ? true : false} variant="standard" label="Line2 font size" {...field} />}/>
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="dateSize"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.dateSize ? true : false} variant="standard" label="Date font size" {...field} />}/>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizFormControl}
                    rules={{ required: true }}
                    name="fontName"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizFormErrors.fontName ? true : false} variant="standard" label="Font Name" {...field} />}/>
                  </div>  
                </div>
              </form>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 1}>
            <TableContainer sx={{my: 3}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Question</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Order</TableCell>
                    <TableCell style={{width: 80}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentQuestions.map((question, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{question.question}</TableCell>
                        <TableCell>{question.type}</TableCell>
                        <TableCell>{question.order}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={evt => deleteQuestion(i)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    rules={{ required: true }}
                    name="question"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionsFormErrors.question ? true : false} variant="standard" label="Question *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    rules={{ required: true }}
                    name="type"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Type *</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={questionsFormErrors.type ? true : false} variant="standard" label="Type *" {...field}><MenuItem value={'Single Answer MCQ'} key={0}>Single Answer MCQ</MenuItem><MenuItem value={'Multiple Answer MCQ'} key={1}>Multiple Answer MCQ</MenuItem></Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    rules={{ required: true }}
                    name="options"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionsFormErrors.options ? true : false} variant="standard" label="Options *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    
                    name="answer"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={questionsFormErrors.answer ? true : false} variant="standard" label="Answer" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    rules={{ required: true }}
                    name="explanation"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={questionsFormErrors.explanation ? true : false} variant="standard" label="Explanation *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionsFormControl}
                    rules={{ required: true }}
                    name="order"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={questionsFormErrors.order ? true : false} variant="standard" label="Order *" {...field} />}/>
                  </div>  
                </div>
              </form>
              <div style={{marginTop: 20}}>
                <Button variant='contained' color='primary' onClick={questionsFormHandler(addQuestion)}>
                  Add Question
                </Button>
              </div>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={quizFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
        <Confirmation
          title='Delete confirmation'
          message={'Are you sure you want to delete the Question. Once deleted the data cannot be recovered.'}
          open={showDeleteConfirmation === 'Question'}
          handleClose={onDeleteQuestionsConfirmation} />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}