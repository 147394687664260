import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`pulse-check/all`, { headers: { secure: 'true' } });
}

export function getPulseCheckById(id) {
  return axiosService.get(`pulse-check/${id}`, { headers: { secure: 'true' } });
}

export function getPulseCheckPage(page, size, sort, order, filters) {
  return axiosService.get(`pulse-check/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createPulseCheck(pulseCheckDetails) {
  return axiosService.post(`pulse-check`, pulseCheckDetails, { headers: { secure: 'true' } });
}

export function updatePulseCheck(id, pulseCheckDetails) {
  return axiosService.put(`pulse-check/${id}`, pulseCheckDetails, { headers: { secure: 'true' } });
}

export function deletePulseCheck(id) {
  return axiosService.delete(`pulse-check/${id}`, { headers: { secure: 'true' } });
}

export function uploadQuestions(id, files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`pulse-check/${id}/questions`, formData, { headers: { secure: 'true' } });
}

export function startPulseCheck(id) {
  return axiosService.put(`pulse-check/${id}/start`, {}, { headers: { secure: 'true' } });
}

export function stopPulseCheck(id) {
  return axiosService.put(`pulse-check/${id}/stop`, {}, { headers: { secure: 'true' } });
}

export function downloadResponses(id) {
  return axiosService.get(`pulse-check/${id}/responses`, { headers: { secure: 'true' } });
}