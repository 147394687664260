import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import { createPulseCheck } from '../../services/pulse-check-service';
import { getAll } from '../../services/organization-service';

const PulseCheckForm = () => {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: pulseCheckFormControl,
    handleSubmit: pulseCheckFormHandler,
    formState: {
      errors: pulseCheckFormErrors
    }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      active: false,
      organization: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [organizations, setOrganizations] = useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const onSubmit = (formData) => {
    formData.questions.forEach(obj => {
      obj.options = obj.options.split('\n');
    });
    setWorkInProgress(true);
    createPulseCheck(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Quiz details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Quiz. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/quizzes', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }

  useEffect(() => {
    getAll().then(response => {
      setOrganizations(response.data.data);
    })
  }, []);

  return (
    <div>
      {workInProgress ? <Spinner /> : <div></div>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>New Pulse Check</h2>
        {rights.includes('read-quiz') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab> : <span>&nbsp;</span>}
      </div>
      <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Pulse Check Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={pulseCheckFormControl}
                      name="organization"
                      render={({ field }) => <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Organization</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={pulseCheckFormErrors.organization ? true : false} variant="standard" label="Organization" {...field}>{organizations.map(org => <MenuItem value={org._id} key={1}>{org.name}</MenuItem>)}</Select></FormControl>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={pulseCheckFormControl}
                      rules={{ required: true }}
                      name="name"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={pulseCheckFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={pulseCheckFormControl}
                      rules={{ required: true }}
                      name="description"
                      render={({ field }) => <TextField multiline rows="8" style={{ width: '100%' }} error={pulseCheckFormErrors.description ? true : false} variant="standard" label="Description *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={pulseCheckFormControl}
                      rules={{ required: true }}
                      name="active"
                      render={({ field }) => <FormControlLabel label="Active *" style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }} control={<Switch {...field} />}></FormControlLabel>} />
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={pulseCheckFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div>
    </div>
  )
}

export default PulseCheckForm