import { Button, Chip, Input } from '@mui/material'
import React from 'react'

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }
  handleDelete(i) {
    this.props.handleDelete(this.props.value[i]);
  }
  handleUpload(event) {
    this.props.handleUpload(event.target.files);
  }
  render() {
    return (
      <div style={{ padding: '10px 0px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <div>{this.props.label}</div>
          <div>
            {this.props.value.map((f, i) => <Chip key={i} label={f.originalname} onDelete={e => this.handleDelete(i)} style={{ margin: '0px 10px' }} />)}
          </div>
          <label htmlFor={this.props.identifier}>
            <Input style={{ display: 'none' }} id={this.props.identifier} multiple type="file" onChange={this.handleUpload} />
            <Button variant="contained" component="span">Upload</Button>
          </label>
        </div>
      </div>
    )
  }
}
