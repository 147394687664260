import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react'
import { Controller, useForm } from 'react-hook-form';

const CourseCompletionReportDetailsDialog = (props) => {
  const {
    control: simpleCompletionControl,
    handleSubmit: simpleCompletionHandler
  } = useForm({
    defaultValues: {
      organization: '',
      course: ''
    }
  });

  return (
    <Dialog open={props.show} onClose={props.onClose}>
      <form>
        <DialogTitle>Course Completion Report</DialogTitle>
        <DialogContent>
          <Typography variant='p'>Choose the course and organization for which you want to download the report.</Typography>
          <Controller control={simpleCompletionControl}
            name="organization"
            render={({ field }) => <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Organization</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={simpleCompletionControl.organization ? true : false} variant="standard" label="Organization" {...field}>{props.organizations.map((organization, i) => <MenuItem key={i} value={organization._id}>{organization.name}</MenuItem>)}</Select></FormControl>} />
          <Controller control={simpleCompletionControl}
            name="course"
            render={({ field }) => <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Course</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={simpleCompletionControl.course ? true : false} variant="standard" label="Organization" {...field}>{props.courses.map((course, i) => <MenuItem key={i} value={course._id}>{course.name}</MenuItem>)}</Select></FormControl>} />
        </DialogContent>
      </form>
      <DialogActions>
        <Button variant='contained' onClick={simpleCompletionHandler(props.handlers[props.handlerIndex])}>Download</Button>
        <Button variant='contained' color='error' onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CourseCompletionReportDetailsDialog;