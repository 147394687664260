import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';

import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { createQuizCandidate } from '../../services/quiz-candidate-service';
import { getAll as quizGetAll } from '../../services/quiz-service'
import { getAll as organizationGetAll } from '../../services/organization-service'



import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function QuizCandidateForm(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: quizCandidateFormControl,
    handleSubmit: quizCandidateFormHandler,
    formState: {
      errors: quizCandidateFormErrors
    },
    getValues: quizCandidateFormGetValues,
    setValue: quizCandidateFormSetValue
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      password: '',
      questionnaire: '',
      started: '',
      currentQuestion: '',
      responses: '',
      ended: false,
      organization: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [quizzes, setQuizzes] = React.useState([]);
  const [organizations, setOrganizations] = React.useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  

  const onSubmit = (formData) => {
    formData.responses = formData.responses.split('\n');
    if (formData.questionnaire === '') {
      formData.questionnaire = null;
    }
    if (formData.organization === '') {
      formData.organization = null;
    }
    setWorkInProgress(true);
    createQuizCandidate(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Quiz candidate details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Quiz candidate. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/quiz-candidates', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }


  React.useEffect(() => {
  setWorkInProgress(true);
  quizGetAll().then(result => {
      setWorkInProgress(false);
      setQuizzes(result.data.data);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Could not fetch related entities for editing. Please contact support.');
      setShowSnackbar(true);
    })
  }, [])
  React.useEffect(() => {
  setWorkInProgress(true);
  organizationGetAll().then(result => {
      setWorkInProgress(false);
      setOrganizations(result.data.data);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Could not fetch related entities for editing. Please contact support.');
      setShowSnackbar(true);
    })
  }, [])

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>New Quiz candidate</h2>
        {rights.includes('read-quiz-candidate') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('create-quiz-candidate') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Quiz candidate Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="email"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.email ? true : false} variant="standard" label="Email *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="name"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    rules={{ required: true }}
                    name="password"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.password ? true : false} variant="standard" label="Password *" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="questionnaire"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Questionnaire</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={quizCandidateFormErrors.questionnaire ? true : false} variant="standard" label="Questionnaire" {...field}>{quizzes && quizzes.length && quizzes.map((quiz, i) => <MenuItem key={i} value={quiz._id}>{quiz.name + ' ' + quiz.date}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="started"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.started ? true : false} variant="standard" label="Started" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="currentQuestion"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={quizCandidateFormErrors.currentQuestion ? true : false} variant="standard" label="Current question" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="responses"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={quizCandidateFormErrors.responses ? true : false} variant="standard" label="Responses" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="ended"
                    render={({ field }) => <FormControlLabel label="Ended" style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }} control={<Switch {...field} />}></FormControlLabel>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={quizCandidateFormControl}
                    
                    name="organization"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-2">Organization</InputLabel><Select id="select-2" labelId="label-2" style={{ width: '100%' }} error={quizCandidateFormErrors.organization ? true : false} variant="standard" label="Organization" {...field}>{organizations && organizations.length && organizations.map((organization, i) => <MenuItem key={i} value={organization._id}>{organization.name}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={quizCandidateFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}