import axiosService from './axios-service';

export const simpleCourseCompletionReport = (reportDetails) => {
  return axiosService.post(`report/simple-course-completion`, reportDetails, { headers: { secure: 'true' } });
}

export const fullCourseCompletionReport = (reportDetails) => {
  return axiosService.post(`report/full-course-completion`, reportDetails, { headers: { secure: 'true' } });
}

export const getDownloadUrl = (filename) => {
  return `${process.env.REACT_APP_DOC_SERVER}/${filename}`;
}