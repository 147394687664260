import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`quiz/all`, { headers: { secure: 'true' } });
}

export function getQuizById(id) {
  return axiosService.get(`quiz/${id}`, { headers: { secure: 'true' } });
}

export function getQuizPage(page, size, sort, order, filters) {
  return axiosService.get(`quiz/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createQuiz(quizDetails) {
  return axiosService.post(`quiz`, quizDetails, { headers: { secure: 'true' } });
}

export function updateQuiz(id, quizDetails) {
  return axiosService.put(`quiz/${id}`, quizDetails, { headers: { secure: 'true' } });
}

export function deleteQuiz(id) {
  return axiosService.delete(`quiz/${id}`, { headers: { secure: 'true' } });
}

export function uploadQuizCertificateBackground(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`quiz/certificate-background`, formData, { headers: { secure: 'true' } });
}

export function getQuizCertificateBackgroundById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`quiz/certificate-background/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteQuizCertificateBackgroundById(id) {
  return axiosService.delete(`quiz/certificate-background/${id}`, { headers: { secure: 'true' } });
}


