import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { uploadOrganizationLogo, deleteOrganizationLogoById, createOrganization } from '../../services/organization-service';
import FileUpload from '../../components/file-upload';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';

export default function OrganizationForm(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: organizationFormControl,
    handleSubmit: organizationFormHandler,
    formState: {
      errors: organizationFormErrors
    },
    getValues: organizationFormGetValues,
    setValue: organizationFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      logo: [],
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      contactAddress: '',
      city: '',
      state: '',
      pinCode: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleLogoUpload = (file) => {
    setWorkInProgress(true);
    uploadOrganizationLogo(file).then(result => {
      setWorkInProgress(false);
      const files = [...organizationFormGetValues().logo];
      files.push(result.data.data[0]);
      organizationFormSetValue('logo', files)
      setSnackbarMessage('Logo uploaded successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Logo could not be uploaded. Please contact support.');
      setShowSnackbar(true);
    });
  }
  const handleLogoDelete = (file) => {
    setWorkInProgress(true);
    deleteOrganizationLogoById(file._id).then(result => {
      setWorkInProgress(false);
      const files = organizationFormGetValues().logo.filter(f => f._id !== file._id);
      organizationFormSetValue('logo', files);
      setSnackbarMessage('Logo deleted successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Logo could not be deleted. Please contact support.');
      setShowSnackbar(true);
    });
  }


  const onSubmit = (formData) => {
    setWorkInProgress(true);
    createOrganization(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Organization details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Organization. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/organizations', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }



  return (
    <div>
      {workInProgress ? <Spinner /> : <div></div>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>New Organization</h2>
        {rights.includes('read-organization') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab> : <span>&nbsp;</span>}
      </div>
      {rights.includes('create-organization') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Organization Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      rules={{ required: true }}
                      name="name"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.name ? true : false} variant="standard" label="Name *" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="logo"
                      render={({ field }) => <FileUpload style={{ width: '100%' }} label="Logo" handleDelete={handleLogoDelete} handleUpload={handleLogoUpload} {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactName"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactName ? true : false} variant="standard" label="Contact name" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactEmail"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactEmail ? true : false} variant="standard" label="Contact email" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactPhone"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactEmail ? true : false} variant="standard" label="Contact Phone" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="contactAddress"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.contactAddress ? true : false} variant="standard" label="Contact address" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="city"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.city ? true : false} variant="standard" label="City" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="state"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.state ? true : false} variant="standard" label="State" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={organizationFormControl}
                      name="pinCode"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={organizationFormErrors.pinCode ? true : false} variant="standard" label="Pin code" {...field} />} />
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={organizationFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
      </div> : <span>You are not authorized to view this page</span>}
    </div>
  )
}