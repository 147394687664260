import { ExitToApp, Menu, Poll, Report } from '@mui/icons-material';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, useTheme } from '@mui/material'
import * as React from 'react';
import { Dashboard } from '@mui/icons-material';
import { Group } from '@mui/icons-material';
import { Settings } from '@mui/icons-material';
import { AccountBalanceSharp } from '@mui/icons-material';
import { SupervisedUserCircle } from '@mui/icons-material';
import { Movie } from '@mui/icons-material';
import { QuestionAnswer } from '@mui/icons-material';
import { School } from '@mui/icons-material';
import { Quiz } from '@mui/icons-material';
import { AssignmentInd } from '@mui/icons-material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import DashboardPage from './dashboard';
import UsersAndRolesPage from './users-and-roles';
import SettingsPage from './settings';
import OrganizationList from './lists/organization-list';
import EmployeeList from './lists/employee-list';
import CourseContentList from './lists/course-content-list';
import QuestionList from './lists/question-list';
import CourseList from './lists/course-list';
import QuizList from './lists/quiz-list';
import QuizCandidateList from './lists/quiz-candidate-list';
import OrganizationForm from './forms/organization-form';
import EmployeeForm from './forms/employee-form';
import CourseContentForm from './forms/course-content-form';
import QuestionForm from './forms/question-form';
import CourseForm from './forms/course-form';
import QuizForm from './forms/quiz-form';
import QuizCandidateForm from './forms/quiz-candidate-form';
import OrganizationEditor from './editors/organization-editor';
import EmployeeEditor from './editors/employee-editor';
import CourseContentEditor from './editors/course-content-editor';
import QuestionEditor from './editors/question-editor';
import CourseEditor from './editors/course-editor';
import QuizEditor from './editors/quiz-editor';
import QuizCandidateEditor from './editors/quiz-candidate-editor';
import { getRole, getPasswordChangeStatus, getName, getRights } from '../services/authorization-service';
import Spinner from '../components/spinner'
import Confirmation from '../components/confirmation';
import { logout } from '../services/admin-user-service';
import Initials from '../components/initials/initials';
import dishaflex from '../images/dishaflex.png';
import Reports from './reports';
import PulseCheckList from './lists/pulse-check-list';
import PulseCheckForm from './forms/pulse-check-form';
import PulseCheckEditor from './editors/pulse-check-editor';

const drawerWidth = 240;

const HomePage = () => {
  const [workInProgress, setWorkInProgress] = React.useState(false);
  const rights = getRights();
  const role = getRole();
  const userName = getName();
  const [openPasswordWarning, setOpenPasswordWarning] = React.useState(!getPasswordChangeStatus());
  const navigate = useNavigate();
  const sidebarTopDivStyle = { display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'center', padding: '20px 0px' }
  const sidebarTopImageStyle = { width: 100, height: 100, objectFit: 'cover', borderRadius: '50%' }
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    let newOpen = !open;
    setOpen(newOpen);
  };
  const showDashboard = async (event) => {
    event.preventDefault();
    navigate('/home/dashboard', { replace: true });
  }
  const showUsersAndRoles = async (event) => {
    event.preventDefault();
    navigate('/home/users-and-roles', { replace: true });
  }
  const showSettings = async (event) => {
    event.preventDefault();
    navigate('/home/settings', { replace: true });
  }

  const showReports = async (event) => {
    event.preventDefault();
    navigate('/home/reports', { replace: true });
  }

  const showPulseChecks = async (event) => {
    event.preventDefault();
    navigate('/home/pulse-checks', { replace: true });
  }

  const onClosingPasswordWarning = () => {
    localStorage.setItem('passwordWarningShown', 'true');
    setOpenPasswordWarning(false);
  }

  const openOrganizations = async (event) => {
    event.preventDefault();
    navigate('/home/organizations', { replace: false });
  }

  const openEmployees = async (event) => {
    event.preventDefault();
    navigate('/home/employees', { replace: false });
  }

  const openCourseContents = async (event) => {
    event.preventDefault();
    navigate('/home/course-contents', { replace: false });
  }

  const openQuestions = async (event) => {
    event.preventDefault();
    navigate('/home/questions', { replace: false });
  }

  const openCourses = async (event) => {
    event.preventDefault();
    navigate('/home/courses', { replace: false });
  }

  const openQuizzes = async (event) => {
    event.preventDefault();
    navigate('/home/quizzes', { replace: false });
  }

  const openQuizCandidates = async (event) => {
    event.preventDefault();
    navigate('/home/quiz-candidates', { replace: false });
  }

  const signOut = () => {
    setWorkInProgress(true);
    logout().then(result => {
      setWorkInProgress(false);
      localStorage.clear();
      navigate('/login', { replace: true });
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      navigate('/login', { replace: true });
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open} style={{
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <Menu />
          </IconButton>
          <div>
            <IconButton color="inherit" onClick={signOut}>
              <ExitToApp />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div style={sidebarTopDivStyle}>
          <img src={dishaflex} style={{ height: '50px' }} />
          <Initials name={userName} />
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '1.5em', lineHeight: '1.8em' }}>{userName}</div>
            <div>{role}</div>
          </div>
        </div>
        <Divider />
        <List>
          <ListItemButton onClick={showDashboard}>
            <ListItemIcon><Dashboard /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          {rights.includes('read-organization') ? <ListItemButton onClick={openOrganizations}>
            <ListItemIcon><AccountBalanceSharp /></ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItemButton> : ``}
          {rights.includes('read-employee') ? <ListItemButton onClick={openEmployees}>
            <ListItemIcon><SupervisedUserCircle /></ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItemButton> : ``}
          {rights.includes('read-course-content') ? <ListItemButton onClick={openCourseContents}>
            <ListItemIcon><Movie /></ListItemIcon>
            <ListItemText primary="Course contents" />
          </ListItemButton> : ``}
          {rights.includes('read-question') ? <ListItemButton onClick={openQuestions}>
            <ListItemIcon><QuestionAnswer /></ListItemIcon>
            <ListItemText primary="Questions" />
          </ListItemButton> : ``}
          {rights.includes('read-course') ? <ListItemButton onClick={openCourses}>
            <ListItemIcon><School /></ListItemIcon>
            <ListItemText primary="Courses" />
          </ListItemButton> : ``}
          {rights.includes('read-quiz') ? <ListItemButton onClick={openQuizzes}>
            <ListItemIcon><Quiz /></ListItemIcon>
            <ListItemText primary="Quizzes" />
          </ListItemButton> : ``}
          {rights.includes('read-quiz-candidate') ? <ListItemButton onClick={openQuizCandidates}>
            <ListItemIcon><AssignmentInd /></ListItemIcon>
            <ListItemText primary="Quiz candidates" />
          </ListItemButton> : ``}
          <ListItemButton onClick={showPulseChecks}>
            <ListItemIcon><Poll /></ListItemIcon>
            <ListItemText primary="Pulse Checks" />
          </ListItemButton>
          <ListItemButton onClick={showReports}>
            <ListItemIcon><Report /></ListItemIcon>
            <ListItemText primary="Reports" />
          </ListItemButton>
          {role === 'Super Admin' ? <ListItemButton onClick={showUsersAndRoles}>
            <ListItemIcon><Group /></ListItemIcon>
            <ListItemText primary="Users and Roles" />
          </ListItemButton> : ``}
          <ListItemButton onClick={showSettings}>
            <ListItemIcon><Settings /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </List>
      </Drawer>
      <div open={open} style={{
        flexGrow: 1,
        overflow: 'auto',
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: 0,
        })
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          padding: theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: 'flex-end'
        }}>
        </div>
        <Routes>
          <Route path="/" element={<DashboardPage />}></Route>
          <Route path="/dashboard" element={<DashboardPage />}></Route>
          <Route path="/users-and-roles" element={<UsersAndRolesPage />}></Route>
          <Route path="/settings" element={<SettingsPage />}></Route>
          <Route path="/new-organization" element={<OrganizationForm />} />
          <Route path="/organizations" element={<OrganizationList />} />
          <Route path="/edit-organization/:id" element={<OrganizationEditor />} />
          <Route path="/new-employee" element={<EmployeeForm />} />
          <Route path="/employees" element={<EmployeeList />} />
          <Route path="/edit-employee/:id" element={<EmployeeEditor />} />
          <Route path="/new-course-content" element={<CourseContentForm />} />
          <Route path="/course-contents" element={<CourseContentList />} />
          <Route path="/edit-course-content/:id" element={<CourseContentEditor />} />
          <Route path="/new-question" element={<QuestionForm />} />
          <Route path="/questions" element={<QuestionList />} />
          <Route path="/edit-question/:id" element={<QuestionEditor />} />
          <Route path="/new-course" element={<CourseForm />} />
          <Route path="/courses" element={<CourseList />} />
          <Route path="/edit-course/:id" element={<CourseEditor />} />
          <Route path="/new-quiz" element={<QuizForm />} />
          <Route path="/quizzes" element={<QuizList />} />
          <Route path="/edit-quiz/:id" element={<QuizEditor />} />
          <Route path="/new-quiz-candidate" element={<QuizCandidateForm />} />
          <Route path="/quiz-candidates" element={<QuizCandidateList />} />
          <Route path="/edit-quiz-candidate/:id" element={<QuizCandidateEditor />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/pulse-checks' element={<PulseCheckList />} />
          <Route path='/new-pulse-check' element={<PulseCheckForm />} />
          <Route path='/edit-pulse-check/:id' element={<PulseCheckEditor />} />
        </Routes>
      </div>
      {workInProgress ? <Spinner /> : <div></div>}
      <Confirmation
        title='Change Password'
        message='Your password is still the default password. Please change your password now to secure your account.'
        open={openPasswordWarning && localStorage.getItem('passwordWarningShown') !== 'true'}
        handleClose={onClosingPasswordWarning} />
    </Box>
  )
}

export default HomePage