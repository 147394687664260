import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`course/all`, { headers: { secure: 'true' } });
}

export function getCourseById(id) {
  return axiosService.get(`course/${id}`, { headers: { secure: 'true' } });
}

export function getCoursePage(page, size, sort, order, filters) {
  return axiosService.get(`course/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createCourse(courseDetails) {
  return axiosService.post(`course`, courseDetails, { headers: { secure: 'true' } });
}

export function updateCourse(id, courseDetails) {
  return axiosService.put(`course/${id}`, courseDetails, { headers: { secure: 'true' } });
}

export function deleteCourse(id) {
  return axiosService.delete(`course/${id}`, { headers: { secure: 'true' } });
}

export function uploadCourseCertificateBackground(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`course/certificate-background`, formData, { headers: { secure: 'true' } });
}

export function getCourseCertificateBackgroundById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`course/certificate-background/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteCourseCertificateBackgroundById(id) {
  return axiosService.delete(`course/certificate-background/${id}`, { headers: { secure: 'true' } });
}

export function importCourseAssignmentList(files, courseId) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  formData.append('course', courseId);
  return axiosService.post(`course/assign`, formData, { headers: { secure: 'true' } });
}

export function uploadCourseSmallImage(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`course/small-image`, formData, { headers: { secure: 'true' } });
}

export function getCourseSmallImageById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`course/small-image/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteCourseSmallImageById(id) {
  return axiosService.delete(`course/small-image/${id}`, { headers: { secure: 'true' } });
}

export function uploadCourseLargeImage(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`course/large-image`, formData, { headers: { secure: 'true' } });
}

export function getCourseLargeImageById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`course/large-image/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteCourseLargeImageById(id) {
  return axiosService.delete(`course/large-image/${id}`, { headers: { secure: 'true' } });
}

