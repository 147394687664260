import { Snackbar, Button, Chip, IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TablePagination, TableContainer, TableSortLabel, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Divider, Typography, Checkbox, FormControlLabel, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useForm, Controller } from "react-hook-form";
import { getAdminUserPage, updateAdminUser, getAdminUserById, deleteAdminUser, createAdminUser } from '../services/admin-user-service';
import { getRolePage, updateRole, getRoleById, deleteRole, createRole } from '../services/admin-role-service';
import Confirmation from '../components/confirmation';
import Spinner from '../components/spinner'

export default function UsersAndRolesPage() {
  const [workInProgress, setWorkInProgress] = useState(false);
  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }
  const [tabValue, setTabValue] = React.useState(0);
  const [adminUsers, setAdminUsers] = useState([])
  const [adminRoles, setAdminRoles] = useState([])
  const [adminUserPage, setAdminUserPage] = useState(0)
  const [adminRolePage, setAdminRolePage] = useState(0)
  const [adminUserPageSize, setAdminUserPageSize] = useState(10)
  const [adminRolePageSize, setAdminRolePageSize] = useState(10)
  const [adminUserSort, setAdminUserSort] = useState('createdAt')
  const [adminUserOrder, setAdminUserOrder] = useState('desc')
  const [adminRoleSort, setAdminRoleSort] = useState('createdAt')
  const [adminRoleOrder, setAdminRoleOrder] = useState('desc')
  const [adminUserCount, setAdminUserCount] = useState(0)
  const [adminRoleCount, setAdminRoleCount] = useState(0)

  const [showUserDelete, setShowUserDelete] = useState(false)
  const [showRoleDelete, setShowRoleDelete] = useState(false)
  const [expendableUser, setExpendableUser] = useState(false)
  const [expendableRole, setExpendableRole] = useState(false)

  // Create and Edit dialog flags

  const [showEditUserDialog, setShowEditUserDialog] = useState(false);
  const [showEditRoleDialog, setShowEditRoleDialog] = useState(false);
  const [showCreateUserDialog, setShowCreateUserDialog] = useState(false);
  const [showCreateRoleDialog, setShowCreateRoleDialog] = useState(false);

  // Create and edit forms

  const {
    handleSubmit: handleSubmitForUserCreate,
    control: createUserControl,
    setValue: setValueForCreateUserForm,
    formState: {
      errors: createUserErrors
    }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      role: ''
    }
  })
  const {
    handleSubmit: handleSubmitForUserEdit,
    control: editUserControl,
    setValue: setValueForEditUserForm,
    formState: {
      errors: editUserErrors
    }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      rights: []
    }
  });

  const {
    handleSubmit: handleSubmitForRoleCreate,
    control: createRoleControl,
    setValue: setValueForCreateRoleForm,
    formState: {
      errors: createRoleErrors
    }
  } = useForm({
    defaultValues: {
      name: '',
      rights: []
    }
  })

  const {
    handleSubmit: handleSubmitForRoleEdit,
    control: editRoleControl,
    setValue: setValueForEditRoleForm,
    formState: {
      errors: editRoleErrors
    }
  } = useForm({
    defaultValues: {
      name: '',
      rights: []
    }
  })

  // Rights array for create/edit user/role
  const [editUserRights, setEditUserRights] = useState([]);
  const [createRoleRights, setCreateRoleRights] = useState([]);
  const [editRoleRights, setEditRoleRights] = useState([]);

  // currentUser and currentRole for the edit forms
  const [currentUser, setCurrentUser] = useState({
    name: '',
    email: '',
    rights: []
  })
  const [currentRole, setCurrentRole] = useState({
    name: '',
    rights: []
  })

  // Current User/Role IDs for editing
  const [currentUserId, setCurrentUserId] = useState('')
  const [currentRoleId, setCurrentRoleId] = useState('')

  // Snackbar action button and flags
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const onEditUser = (id) => {
    setWorkInProgress(true);
    getAdminUserById(id).then(result => {
      setWorkInProgress(false);
      setCurrentUserId(id)
      setCurrentUser(result.data.data)
      setShowEditUserDialog(true)
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('Invalid admin user. Please refresh and retry or contact support.')
      setShowSnackbar(true)
    })
  }

  const onEditRole = (id) => {
    setWorkInProgress(true);
    getRoleById(id).then(result => {
      setWorkInProgress(false);
      setCurrentRoleId(id)
      setCurrentRole(result.data.data)
      setShowEditRoleDialog(true)
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('Invalid admin role. Please refresh and retry or contact support.')
      setShowSnackbar(true)
    })

  }

  const onDeleteUser = (id) => {
    setExpendableUser(id);
    setShowUserDelete(true);
  }

  const onDeleteRole = (id) => {
    setExpendableRole(id);
    setShowRoleDelete(true);
  }

  const openCreateUserDialog = () => {
    setValueForCreateUserForm('name', '')
    setValueForCreateUserForm('email', '')
    setValueForCreateUserForm('role', '')
    setShowCreateUserDialog(true)
  }

  const openCreateRoleDialog = () => {
    setValueForCreateRoleForm('name', '')
    setCreateRoleRights([])
    setShowCreateRoleDialog(true)
  }

  // Pagination functions for the user and role list

  const onUserRowsPerPageChange = (event) => {
    setAdminUserPageSize(event.target.value)
  }

  const onRoleRowsPerPageChange = (event) => {
    setAdminRolePageSize(event.target.value)
  }

  const onUserPageChange = (event, page) => {
    setAdminUserPage(page)
  }

  const onRolePageChange = (event, page) => {
    setAdminRolePage(page)
  }

  const onUserSortChange = (column) => {
    if (adminUserSort === column) {
      if (adminUserOrder === 'asc') {
        setAdminUserOrder('desc');
      }
      else {
        setAdminUserOrder('asc');
      }
    }
    else {
      setAdminUserOrder('asc');
      setAdminUserSort(column);
    }
  }

  const onRoleSortChange = (column) => {
    if (adminRoleSort === column) {
      if (adminRoleOrder === 'asc') {
        setAdminRoleOrder('desc');
      }
      else {
        setAdminRoleOrder('asc');
      }
    }
    else {
      setAdminRoleOrder('asc');
      setAdminRoleSort(column);
    }
  }

  const onDeleteUserConfirmationClose = (result) => {
    if (result === true) {
      setWorkInProgress(true);
      deleteAdminUser(expendableUser).then(result => {
        setWorkInProgress(false);
        setSnackbarMessage('User deleted successfully.')
        setShowSnackbar(true)
        setShowUserDelete(false);
        fetchUsers()
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Could not delete the user. Please contact support.')
        setShowSnackbar(true)
      })
    }
  }

  const onDeleteRoleConfirmationClose = (result) => {
    if (result) {
      setWorkInProgress(true);
      deleteRole(expendableRole).then(result => {
        setWorkInProgress(false);
        setSnackbarMessage('Role deleted successfully.')
        setShowSnackbar(true)
        setShowRoleDelete(false);
        fetchRoles()
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Could not delete the role. Please contact support.')
        setShowSnackbar(true)
      })
    }
  }

  const hideDialog = () => {
    setShowCreateUserDialog(false)
    setShowCreateRoleDialog(false)
    setShowEditUserDialog(false)
    setShowEditRoleDialog(false)
  }

  const deleteRightForEditUser = (index) => {
    const newRights = [...editUserRights]
    newRights.splice(index, 1)
    setEditUserRights(newRights)
  }

  const addRightForEditUser = (e) => {
    if (e.key === 'Enter') {
      if (!editUserRights.includes(e.target.value)) {
        setEditUserRights([...editUserRights, e.target.value])
        e.target.value = ''
      }
      else {
        setSnackbarMessage('Right already present for the user.')
        setShowSnackbar(true)
      }
    }
  }

  const addRightForEditRole = (e) => {
    if (e.key === 'Enter') {
      if (!editRoleRights.includes(e.target.value)) {
        setEditRoleRights([...editRoleRights, e.target.value])
        e.target.value = ''
      }
      else {
        setSnackbarMessage('Right already present for the role.')
        setShowSnackbar(true)
      }
    }
  }

  const deleteRightForCreateRole = (index) => {
    const newRights = [...createRoleRights]
    newRights.splice(index, 1)
    setCreateRoleRights(newRights)
  }

  const deleteRightForEditRole = (index) => {
    const newRights = [...editRoleRights]
    newRights.splice(index, 1)
    setEditRoleRights(newRights)
  }

  const addRightForCreateRole = (e) => {
    if (e.key === 'Enter') {
      if (!createRoleRights.includes(e.target.value)) {
        setCreateRoleRights([...createRoleRights, e.target.value])
        e.target.value = ''
      }
      else {
        setSnackbarMessage('Right already present for the role.')
        setShowSnackbar(true)
      }
    }
  }

  const createUser = (formData) => {
    setWorkInProgress(true);
    createAdminUser(formData).then(result => {
      setWorkInProgress(false);
      setValueForCreateUserForm('name', '')
      setValueForCreateUserForm('email', '')
      setValueForCreateUserForm('role', '')
      setSnackbarMessage('New user created successfully.')
      setShowSnackbar(true);
      setShowCreateUserDialog(false)
      fetchUsers()
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('User details could not be saved. Please contact support.')
      setShowSnackbar(true);
    });
  }

  const updateUser = (formData) => {
    formData.rights = editUserRights;
    setWorkInProgress(true);
    updateAdminUser(currentUserId, formData).then(result => {
      setWorkInProgress(false);
      setSnackbarMessage('User details updated successfully.')
      setShowSnackbar(true);
      setShowEditUserDialog(false)
      fetchUsers()
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('User details could not be udpated. Please contact support.')
      setShowSnackbar(true);
    });
  }

  const createAdminRole = (formData) => {
    formData.rights = createRoleRights;
    setWorkInProgress(true);
    createRole(formData).then(result => {
      setWorkInProgress(false);
      setValueForCreateRoleForm('name', '')
      setCreateRoleRights([])
      setSnackbarMessage('Role created successfully.')
      setShowSnackbar(true);
      setShowCreateRoleDialog(false)
      fetchRoles()
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('Role could not be created. Please contact support.')
      setShowSnackbar(true);
    });
  }

  const updateAdminRole = (formData) => {
    formData.rights = editRoleRights;
    setWorkInProgress(true);
    updateRole(currentRoleId, formData).then(result => {
      setWorkInProgress(false);
      setSnackbarMessage('Role updated successfully.')
      setShowSnackbar(true);
      setShowEditRoleDialog(false)
      fetchRoles()
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err)
      setSnackbarMessage('Role could not be udpated. Please contact support.')
      setShowSnackbar(true);
    });
  }

  const fetchRightsFromRole = (e) => {
    if (e.target.checked) {
      setEditUserRights(currentUser.rights);
    }
  }

  const populateSuperAdminRights = () => {
    const superAdminRole = adminRoles.filter(role => role.name === 'Super Admin')[0];
    if (superAdminRole) {
      setEditRoleRights([...superAdminRole.rights])
    }
  }
  
  const fetchUsers = () => {
    setWorkInProgress(true);
    getAdminUserPage(adminUserPage, adminUserPageSize, adminUserSort, adminUserOrder).then(result => {
      setWorkInProgress(false);
      setAdminUsers(result.data.data.docs);
      setAdminUserCount(result.data.data.totalDocs);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Could not fetch admin users. Please contact support.')
      setShowSnackbar(true)
      console.log(err);
    })
  }

  const fetchRoles = () => {
    setWorkInProgress(true);
    getRolePage(adminRolePage, adminRolePageSize, adminRoleSort, adminRoleOrder).then(result => {
      setWorkInProgress(false);
      setAdminRoles(result.data.data.docs);
      setAdminRoleCount(result.data.data.totalDocs);
    }).catch(err => {
      setWorkInProgress(false);
      setSnackbarMessage('Could not fetch admin roles. Please contact support.')
      setShowSnackbar(true)
      console.log(err);
    })
  }

  useEffect(() => {
    fetchUsers()
  }, [adminUserPage, adminUserPageSize, adminUserSort, adminUserOrder])

  useEffect(() => {
    fetchRoles()
  }, [adminRolePage, adminRolePageSize, adminRoleSort, adminRoleOrder])

  useEffect(() => {
    setValueForEditUserForm('name', currentUser.name)
    setValueForEditUserForm('email', currentUser.email)
    setEditUserRights(currentUser.rights)
  }, [currentUser])

  useEffect(() => {
    setValueForEditRoleForm('name', currentRole.name)
    setEditRoleRights(currentRole.rights)
  }, [currentRole])

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <h2>User/Role Management</h2>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChangeTab}>
            <Tab label="Users" />
            <Tab label="Roles &amp; Rights" />
          </Tabs>
        </Box>
        <div role="tabpanel" hidden={tabValue !== 0}>
          <Box sx={{ pt: 3, pb: 3 }}>
            <Button variant="contained" color="primary" onClick={openCreateUserDialog}>
              <AddIcon />
              Add User
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={adminUserSort === 'name' ? adminUserOrder : false}>
                    <TableSortLabel active={adminUserSort === 'name'} direction={adminUserOrder === 'name' ? adminUserOrder : 'asc'} onClick={evt => onUserSortChange('name')}>Name</TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={adminUserSort === 'email' ? adminUserOrder : false}>
                    <TableSortLabel active={adminUserSort === 'email'} direction={adminUserOrder === 'email' ? adminUserOrder : 'asc'} onClick={evt => onUserSortChange('email')}>Email Address</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel active={adminUserSort === 'passwordChanged'} direction={adminUserOrder === 'passwordChanged' ? adminUserOrder : 'asc'} onClick={evt => onUserSortChange('passwordChanged')}>Password changed?</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel active={adminUserSort === 'role'} direction={adminUserOrder === 'role' ? adminUserOrder : 'asc'} onClick={evt => onUserSortChange('role')}>Role</TableSortLabel>
                  </TableCell>
                  <TableCell style={{ width: 80 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminUsers.map((adminUser, key) => <TableRow key={key}>
                  <TableCell>{adminUser.name}</TableCell>
                  <TableCell>{adminUser.email}</TableCell>
                  <TableCell>{adminUser.hasPasswordChanged ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{adminUser.role.name}</TableCell>
                  <TableCell style={{ padding: 0 }}>
                    <IconButton color="primary" disabled={adminUser.name === 'Admin User'} onClick={evt => onEditUser(adminUser._id)} sx={{ margin: 0 }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" disabled={adminUser.name === 'Admin User'} onClick={evt => onDeleteUser(adminUser._id)} sx={{ margin: 0 }}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={adminUserCount}
              page={adminUserPage}
              onPageChange={onUserPageChange}
              rowsPerPage={adminUserPageSize}
              onRowsPerPageChange={onUserRowsPerPageChange}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </TableContainer>
        </div>
        <div role="tabpanel" hidden={tabValue !== 1}>
          <Box sx={{ pt: 3, pb: 3 }}>
            <Button variant="contained" color="primary" onClick={openCreateRoleDialog}>
              <AddIcon />
              Add Role
            </Button>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={adminRoleSort === 'name' ? adminRoleOrder : false}>
                    <TableSortLabel active={adminRoleSort === 'name'} direction={adminRoleOrder === 'name' ? adminRoleOrder : 'asc'} onClick={evt => onRoleSortChange('name')}>Name</TableSortLabel>
                  </TableCell>
                  <TableCell>Rights</TableCell>
                  <TableCell style={{ width: 80 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminRoles.map((adminRole, key) => <TableRow key={key}>
                  <TableCell>{adminRole.name}</TableCell>
                  <TableCell>{adminRole.rights.map((right, key) => <Chip key={key} style={{ margin: 5 }} label={right} />)}</TableCell>
                  <TableCell style={{ padding: 0 }}>
                    <IconButton color="primary" disabled={adminRole.name === 'Super Admin'} onClick={evt => onEditRole(adminRole._id)} sx={{ margin: 0 }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="error" disabled={adminRole.name === 'Super Admin'} onClick={evt => onDeleteRole(adminRole._id)} sx={{ margin: 0 }}>
                      <DeleteForeverIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={adminRoleCount}
              page={adminRolePage}
              onPageChange={onRolePageChange}
              rowsPerPage={adminRolePageSize}
              onRowsPerPageChange={onRoleRowsPerPageChange}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
          </TableContainer>
        </div>
      </Box>
      <Dialog open={showEditUserDialog} onClose={hideDialog}>
        <DialogTitle>Edit Admin User</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={editUserControl} rules={{ required: true }} name="name" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={editUserErrors.name ? true : false} label="Name" />}>
                </Controller>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={editUserControl} name="email" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={editUserErrors.email ? true : false} label="Email" />}>
                </Controller>
              </div>
            </div>
            <Typography style={{ margin: '15px 0px 15px 0px' }}>Rights</Typography>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                {editUserRights.map((right, i) => <Chip style={{ margin: '0px 5px 5px 0px' }} key={i} label={right} onDelete={evt => deleteRightForEditUser(i)} />)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <TextField style={{ width: '100%' }} variant="standard" label="Add a right here..." onKeyDown={addRightForEditUser} />
              </div>
            </div>
            <div>
              <FormControlLabel label="All rights from role" control={<Checkbox onChange={fetchRightsFromRole} />}></FormControlLabel>
            </div>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSubmitForUserEdit(updateUser)}>Save</Button>
          <Button onClick={hideDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCreateUserDialog} onClose={hideDialog}>
        <DialogTitle>New Admin User</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={createUserControl} rules={{ required: true }} name="name" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={createUserErrors.name ? true : false} label="Name *" />}>
                </Controller>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={createUserControl} rules={{ required: true }} name="email" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={createUserErrors.email ? true : false} label="Email *" />}>
                </Controller>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={createUserControl} rules={{ required: true }} name="role" render={({ field }) =>
                  <FormControl style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }}>
                    <InputLabel id="label-1">Role *</InputLabel>
                    <Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={createUserErrors.role ? true : false} variant="standard" label="Role *" {...field}>
                      {adminRoles.map((adminRole, i) => <MenuItem key={i} value={adminRole._id}>{adminRole.name}</MenuItem>)}
                    </Select>
                  </FormControl>}>
                </Controller>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitForUserCreate(createUser)}>Save</Button>
          <Button onClick={hideDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showCreateRoleDialog} onClose={hideDialog}>
        <DialogTitle>New Admin Role</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={createRoleControl} rules={{ required: true }} name="name" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={createRoleErrors.name ? true : false} label="Name *" />}>
                </Controller>
              </div>
            </div>
            <Typography style={{ margin: '15px 0px 15px 0px' }}>Rights</Typography>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                {createRoleRights.map((right, i) => <Chip style={{ margin: '0px 5px 5px 0px' }} key={i} label={right} onDelete={evt => deleteRightForCreateRole(i)} />)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <TextField style={{ width: '100%' }} variant="standard" label="Add a right here..." onKeyDown={addRightForCreateRole} />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitForRoleCreate(createAdminRole)}>Save</Button>
          <Button onClick={hideDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showEditRoleDialog} onClose={hideDialog}>
        <DialogTitle>Edit Admin Role</DialogTitle>
        <DialogContent>
          <form>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={editRoleControl} rules={{ required: true }} name="name" render={({ field }) =>
                  <TextField style={{ width: '100%' }} variant="standard" {...field} error={editRoleErrors.name ? true : false} label="Name" />}>
                </Controller>
              </div>
            </div>
            <Typography style={{ margin: '15px 0px 15px 0px' }}>Rights</Typography>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                {editRoleRights.map((right, i) => <Chip style={{ margin: '0px 5px 5px 0px' }} key={i} label={right} onDelete={evt => deleteRightForEditRole(i)} />)}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <TextField style={{ width: '100%' }} variant="standard" label="Add a right here..." onKeyDown={addRightForEditRole} />
              </div>
            </div>
            <div>
              <FormControlLabel label="Get all rights from super admin" control={<Checkbox onChange={populateSuperAdminRights} />}></FormControlLabel>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitForRoleEdit(updateAdminRole)}>Save</Button>
          <Button onClick={hideDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
      <Confirmation
        title='Delete confirmation'
        message={'Are you sure you want to delete the user? Records once deleted cannot be recovered.'}
        open={showUserDelete}
        handleClose={onDeleteUserConfirmationClose} />
      <Confirmation
        title='Delete confirmation'
        message={'Are you sure you want to delete the role? Records once deleted cannot be recovered.'}
        open={showRoleDelete}
        handleClose={onDeleteRoleConfirmationClose} />
    </div>
  )
}
