import { Input, Fab } from '@mui/material'
import React from 'react'
import { UploadSharp } from '@mui/icons-material';

const FileUploadFab = (props) => {

  let handleUpload = (event) => {
    props.handleUpload(event.target.files);
  }

  return (
    <span style={{ padding: '10px 0px' }}>
      <span style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
        <label htmlFor={props.identifier}>
          <Input style={{ display: 'none' }} id={props.identifier} multiple type="file" onChange={handleUpload} />
          <Fab size='small' color='primary' component='span'>
            <UploadSharp />
          </Fab>
        </label>
      </span>
    </span>
  )
}

export default FileUploadFab