import jwt_decode from 'jwt-decode'

export function getRights() {
    const decoded = jwt_decode(localStorage.getItem('authToken'));
    return decoded.rights;
}

export function getRole() {
    const decoded = jwt_decode(localStorage.getItem('authToken'));
    return decoded.roleName;
}

export function getPasswordChangeStatus() {
    const decoded = jwt_decode(localStorage.getItem('authToken'));
    return decoded.hasPasswordChanged === 'true';
}

export function getName() {
    const decoded = jwt_decode(localStorage.getItem('authToken'));
    return decoded.name;
}
