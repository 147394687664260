import { Button, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Spinner from '../components/spinner'

export default function DashboardPage() {
  const [workInProgress, setWorkInProgress] = useState(false);
  const colors = ['#F2545B', '#19323C', '#774936', '#74226C', '#1B263B', '#0267C1', '#D65108', '#037171'];
  const [summaries, setSummaries] = useState([]);
  const [latestData, setLatestData] = useState({});
  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [tableRows, setTableRows] = useState([])

  useEffect(() => {
  }, [latestData, summaries]);

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <h2>Dashboard</h2>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 20, marginBottom: 30 }}>
        
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  )
}
