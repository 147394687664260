import { Button, Fab, Snackbar, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, TablePagination, IconButton, TableSortLabel } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getOrganizationPage, deleteOrganization } from '../../services/organization-service';
import Confirmation from '../../components/confirmation';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';

export default function OrganizationList(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const rights = getRights()
  const [pageData, setPageData] = useState({
    data: [],
    count: -1
  })
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [sort, setSort] = useState('createdAt')
  const [order, setOrder] = useState('desc')
  const [filters, setFilters] = useState({})
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [idToDelete, setIdToDelete] = useState(-1);

  const navigate = useNavigate();
  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
  </React.Fragment>

  function showForm(event) {
    navigate('/home/new-organization', { replace: false });
  }

  function handleChangeRowsPerPage(event) {
    setPageSize(event.target.value);
  }

  function handleChangePage(event, page) {
    setPage(page);
  }

  function editRecord(id) {
    navigate(`/home/edit-organization/${id}`, { replace: false })
  }

  function deleteRecord(id) {
    setIdToDelete(id);
    setOpenConfirmation(true);
  }

  function onConfirmationClose(response) {
    setOpenConfirmation(false);
    if (response) {
      setWorkInProgress(true);
      deleteOrganization(idToDelete).then(result => {
        setWorkInProgress(false);
        setSnackbarMessage('Organization deleted successfully.');
        setShowSnackbar(true);
        setFilters({});
      }).catch(err => {
        setWorkInProgress(false);
        console.log(err);
        setSnackbarMessage('Organization could not be deleted. Please contact support.');
        setShowSnackbar(true);
      });
    }
  }

  function updateSortAndOrder(column) {
    if (sort === column) {
      if (order === 'asc') {
        setOrder('desc');
      }
      else {
        setOrder('asc');
      }
    }
    else {
      setOrder('asc');
      setSort(column);
    }
  }


  useEffect(() => {
    setWorkInProgress(true);
    getOrganizationPage(page, pageSize, sort, order, filters).then(result => {
      setWorkInProgress(false);
      if (result.data.data) {
        setPageData({
          data: result.data.data.docs,
          count: result.data.data.totalDocs
        })
      }
    }).catch(err => {
      setWorkInProgress(false);
      setShowSnackbar(true);
      setSnackbarMessage('Error occured while fetching data. Please contact support.');
    })
  }, [sort, order, page, pageSize, filters])

  return (
    <div>
      {workInProgress ? <Spinner /> : <div></div>}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Organization List</h2>
        {rights.includes('create-organization') ? <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}><Fab size="small" color="secondary" aria-label="add" onClick={showForm}>
          <AddIcon />
        </Fab> <span></span></span> : <span></span>}
      </div>
      {rights.includes('create-organization') ? <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={sort === 'name' ? order : false}>
                <TableSortLabel active={sort === 'name'} direction={sort === 'name' ? order : 'asc'} onClick={evt => updateSortAndOrder('name')}>
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'contactName' ? order : false}>
                <TableSortLabel active={sort === 'contactName'} direction={sort === 'contactName' ? order : 'asc'} onClick={evt => updateSortAndOrder('contactName')}>
                  Contact name
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'contactEmail' ? order : false}>
                <TableSortLabel active={sort === 'contactEmail'} direction={sort === 'contactEmail' ? order : 'asc'} onClick={evt => updateSortAndOrder('contactEmail')}>
                  Contact email
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'contactPhone' ? order : false}>
                <TableSortLabel active={sort === 'contactPhone'} direction={sort === 'contactPhone' ? order : 'asc'} onClick={evt => updateSortAndOrder('contactPhone')}>
                  Contact Phone
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'contactAddress' ? order : false}>
                <TableSortLabel active={sort === 'contactAddress'} direction={sort === 'contactAddress' ? order : 'asc'} onClick={evt => updateSortAndOrder('contactAddress')}>
                  Contact address
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'city' ? order : false}>
                <TableSortLabel active={sort === 'city'} direction={sort === 'city' ? order : 'asc'} onClick={evt => updateSortAndOrder('city')}>
                  City
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'state' ? order : false}>
                <TableSortLabel active={sort === 'state'} direction={sort === 'state' ? order : 'asc'} onClick={evt => updateSortAndOrder('state')}>
                  State
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={sort === 'pinCode' ? order : false}>
                <TableSortLabel active={sort === 'pinCode'} direction={sort === 'pinCode' ? order : 'asc'} onClick={evt => updateSortAndOrder('pinCode')}>
                  Pin code
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: 80, padding: 0 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pageData && pageData.data && pageData.data.map((row, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.contactName}</TableCell>
                  <TableCell>{row.contactEmail}</TableCell>
                  <TableCell>{row.contactPhone}</TableCell>
                  <TableCell>{row.contactAddress}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.state}</TableCell>
                  <TableCell>{row.pinCode}</TableCell>
                  <TableCell className="list-cell" style={{ padding: 0 }}>
                    {rights.includes('update-organization') ? <IconButton color="primary" onClick={evt => editRecord(row._id)} sx={{ margin: 0 }}>
                      <EditIcon />
                    </IconButton> : <span>&nbsp;</span>}
                    {rights.includes('delete-organization') ? <IconButton color="error" onClick={evt => deleteRecord(row._id)} sx={{ margin: 0 }}>
                      <DeleteForeverIcon />
                    </IconButton> : <span>&nbsp;</span>}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={pageData.count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      </TableContainer> : <div>You are not authorized to view this page</div>}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
      <Confirmation
        title='Delete confirmation'
        message='Are you sure you want to delete the Organization? Once deleted it cannot be recovered.'
        open={openConfirmation}
        handleClose={onConfirmationClose} />
    </div>
  )
}
