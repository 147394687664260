import { Button, Paper, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { forgotPassword } from '../services/admin-user-service'
import pageBg from '../images/login-bg1.png'
import logo from '../images/logo.png'
import banner from '../images/banner-1.jpg'
import { Link } from 'react-router-dom'
import Spinner from '../components/spinner'

export default function ForgotPasswordPage() {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Please check your email for the link to reset password.');
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const onSubmit = (formData) => {
    setWorkInProgress(true);
    forgotPassword(formData).then(result => {
      setWorkInProgress(false);
      if (result.data.data && result.data.data.message && result.data.data.message === 'Success') {
        setShowSnackbar(true);
        setSnackbarMessage('Please check your email for the link to reset password.');
      }
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setShowSnackbar(true);
      setSnackbarMessage('Failed to send link to reset password. Please contact support.');
    });
  }
  const outerDivStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    backgroundImage: `url(${pageBg})`,
    backgroundSize: 'cover'
  }
  const paperStyle = {
    display: 'flex',
    flexDirection: 'row',
    margin: 10
  }
  const imgStyle = {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    height: '65vh'
  }
  const formDivStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }

  const action = (
    <React.Fragment>
      <Button style={{ textTransform: 'capitalize' }} color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  );

  return (
    <div style={outerDivStyle}>
      {workInProgress?<Spinner/>:<div></div>}
      <Paper style={paperStyle} elevation={3}>
        <img src={banner} alt="login-banner" style={imgStyle} />
        <div style={formDivStyle}>
          <div style={{ padding: 30 }}>
            <img src={logo} style={{ height: 50, marginBottom: 10 }} />
            <Typography fontSize="1.3em">Forgot Password?</Typography>
            <p>Enter your registered Email ID to reset password</p>
            <form>
              <div className='formField'>
                <Controller control={control} rules={{ required: true }} name="email" render={({ field }) => <TextField error={errors.email ? true : false} variant="standard" label="Email" {...field} />} />
              </div>
              <div style={{ paddingBottom: 10 }}>
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>Send Recovery Email</Button>
              </div>
              <Typography><Link to="/login">Sign in</Link> using your email and password.</Typography>
            </form>
          </div>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={action}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  )
}
