import axiosService from './axios-service';

export function getAll() {
  return axiosService.get(`organization/all`, { headers: { secure: 'true' } });
}

export function getOrganizationById(id) {
  return axiosService.get(`organization/${id}`, { headers: { secure: 'true' } });
}

export function getOrganizationPage(page, size, sort, order, filters) {
  return axiosService.get(`organization/page?size=${size}&page=${page}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}

export function createOrganization(organizationDetails) {
  return axiosService.post(`organization`, organizationDetails, { headers: { secure: 'true' } });
}

export function updateOrganization(id, organizationDetails) {
  return axiosService.put(`organization/${id}`, organizationDetails, { headers: { secure: 'true' } });
}

export function deleteOrganization(id) {
  return axiosService.delete(`organization/${id}`, { headers: { secure: 'true' } });
}

export function uploadOrganizationLogo(files) {
  const fileArray = Object.keys(files).map(i => files[i]);
  const formData = new FormData();
  fileArray.forEach(file => {
    formData.append('file', file, file.name);
  });
  return axiosService.post(`organization/logo`, formData, { headers: { secure: 'true' } });
}

export function getOrganizationLogoById(ids) {
  const fileIds = ids.join(',');
  return axiosService.get(`organization/logo/${fileIds}`, { headers: { secure: 'true' } });
}

export function deleteOrganizationLogoById(id) {
  return axiosService.delete(`organization/logo/${id}`, { headers: { secure: 'true' } });
}

export const getCoursesForOrg = async (organization, page, pageSize, sort, order) => {
  return axiosService.get(`organization/${organization}/courses?page=${page}&size=${pageSize}&sort=${sort}&order=${order}`, { headers: { secure: 'true' } });
}