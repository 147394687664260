import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Snackbar, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { updatePassword } from '../services/admin-user-service'
import Spinner from '../components/spinner'

export default function SettingsPage() {
  const [workInProgress, setWorkInProgress] = useState(false);
  const navigate = useNavigate()
  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      retypeNewPassword: ''
    }
  })

  const onShowChangePasswordDialog = () => {
    setValue('currentPassword', '')
    setValue('newPassword', '')
    setValue('retypeNewPassword', '')
    setShowChangePasswordDialog(true)
  }

  const updateAdminUserPassword = (formData) => {
    if (formData.newPassword !== formData.retypeNewPassword) {
      setSnackbarMessage('Passwords do not match. Please make sure that the passwords in the New Pasword and Retype New Password fields match.')
      setShowSnackbar(true)
      return
    }
    setWorkInProgress(true);
    updatePassword(formData.currentPassword, formData.newPassword).then(result => {
      setWorkInProgress(false);
      setSnackbarMessage('Password updated successfully.')
      setShowSnackbar(true)
      setValue('currentPassword', '')
      setValue('newPassword', '')
      setValue('retypeNewPassword', '')
      hideDialog()
      setTimeout(() => {
        localStorage.clear()
        navigate('/login')
      }, 2000)
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Error occured while updating password. Please contact support.')
      setShowSnackbar(true)
    })
  }

  const hideDialog = () => {
    setShowChangePasswordDialog(false)
  }

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <h2>Settings</h2>
      <div style={{ display: 'flex' }}>
        <Paper style={{ padding: 30 }}>
          <h3 style={{ fontWeight: 500 }}>Change Password</h3>
          <p style={{ marginBottom: 30 }}>Change your login password. You would be logged off once your password is changed to use the new password.</p>
          <Button variant="contained" color="primary" onClick={onShowChangePasswordDialog}>Change Password</Button>
        </Paper>
      </div>
      <Dialog open={showChangePasswordDialog} onClose={hideDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <form style={{ minWidth: 400 }}>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={control} rules={{ required: true }} name="currentPassword" render={({ field }) =>
                  <TextField type="password" style={{ width: '100%' }} variant="standard" {...field} error={errors.currentPassword ? true : false} label="Current Password *" />}>
                </Controller>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={control} rules={{ required: true }} name="newPassword" render={({ field }) =>
                  <TextField type="password" style={{ width: '100%' }} variant="standard" {...field} error={errors.newPassword ? true : false} label="New Password *" />}>
                </Controller>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
              <div style={formFieldStyle}>
                <Controller control={control} rules={{ required: true }} name="retypeNewPassword" render={({ field }) =>
                  <TextField type="password" style={{ width: '100%' }} variant="standard" {...field} error={errors.retypeNewPassword ? true : false} label="Retype Password *" />}>
                </Controller>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit(updateAdminUserPassword)}>Update</Button>
          <Button onClick={hideDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        action={<React.Fragment><Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button></React.Fragment>}
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  )
}
