import { Snackbar, Fab, Box, Tab, Tabs, Button, TextField, FormControl, Select, MenuItem, InputLabel, FormControlLabel, Switch, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Input } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ListIcon from '@mui/icons-material/List';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form'
import { uploadCourseCertificateBackground, deleteCourseCertificateBackgroundById, createCourse } from '../../services/course-service';
import { getAll as courseContentGetAll } from '../../services/course-content-service'
import { getAll as questionGetAll } from '../../services/question-service'
import FileUpload from '../../components/file-upload';
import { getRights } from '../../services/authorization-service';
import Spinner from '../../components/spinner';
import Confirmation from '../../components/confirmation';

export default function CourseForm(props) {
  const [workInProgress, setWorkInProgress] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState('none');
  const [expendableObjectId, setExpendableObjectId] = useState('');
  const [expendableQuestionId, setExpendableQuestionId] = useState(-1);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const rights = getRights()
  const navigate = useNavigate();
  const {
    control: courseFormControl,
    handleSubmit: courseFormHandler,
    formState: {
      errors: courseFormErrors
    },
    getValues: courseFormGetValues,
    setValue: courseFormSetValue
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      level: '',
      code: '',
      duration: '',
      credits: '',
      introduction: '',
      hasQuiz: false,
      quizQualifyingScore: 0
    }
  });

  const {
    control: sectionsFormControl,
    handleSubmit: sectionsFormHandler,
    formState: {
      errors: sectionsFormErrors
    }
  } = useForm({
    defaultValues: {
      content: '',
      scenario: '',
      description: '',
      questions: [],
      percentage: ''
    }
  });

  const {
    control: questionFormControl,
    handleSubmit: questionFormHandler,
    formState: {
      errors: questionFormErrors
    }
  } = useForm({
    defaultValues: {
      questionType: '',
      questionText: '',
      options: '',
      correctOption: ''
    }
  });

  const {
    control: certificateFormControl,
    handleSubmit: certificateFormHandler,
    formState: {
      errors: certificateFormErrors
    },
    getValues: certificateFormGetValues,
    setValue: certificateFormSetValue,
  } = useForm({
    defaultValues: {
      certificateLine1: 'Certificate of completion',
      certificateLine2: 'This certificate is awarded to',
      certificateLine3: 'Chandrakant Singh',
      certificateLine4: 'For sucessful completion of course on Prevention of Sexual Harassment',
      line1Position: '',
      line2Position: '',
      line3Position: '',
      line4Position: '',
      line1Size: 16,
      line2Size: 16,
      line3Size: 16,
      line4Size: 16,
      line1Font: 'sans-serif',
      line2Font: 'sans-serif',
      line3Font: 'sans-serif',
      line4Font: 'sans-serif',
      certificate: false,
      certificateBackground: [],
      resolution: '',
      nameSize: '',
      namePosition: '',
      dateSize: '',
      datePosition: ''
    }
  });

  const action = <React.Fragment>
    <Button color="secondary" size="small" onClick={(event) => setShowSnackbar(false)}>Close</Button>
    </React.Fragment>
  const [tabValue, setTabValue] = React.useState(0);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [courseContents, setCourseContents] = React.useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [currentSections, setCurrentSections] = React.useState([]);
  const [currentQuizQuestions, setCurrentQuizQuestions] = useState([]);

  const handleChangeTab = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

    const handleCertificateBackgroundUpload = (file) => {
      setWorkInProgress(true);
      uploadCourseCertificateBackground(file).then(result => {
        setWorkInProgress(false);
        const files = [...courseFormGetValues().certificateBackground];
        files.push(result.data.data[0]);
        courseFormSetValue('certificateBackground', files)
        setSnackbarMessage('Certificate background uploaded successfully.');
        setShowSnackbar(true);
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Certificate background could not be uploaded. Please contact support.');
        setShowSnackbar(true);
      });
    }
    const handleCertificateBackgroundDelete = (file) => {
      setWorkInProgress(true);
      deleteCourseCertificateBackgroundById(file._id).then(result => {
        setWorkInProgress(false);
        const files = courseFormGetValues().certificateBackground.filter(f => f._id != file._id);
        courseFormSetValue('certificateBackground', files);
        setSnackbarMessage('Certificate background deleted successfully.');
        setShowSnackbar(true);
      }).catch(err => {
        setWorkInProgress(false);
        setSnackbarMessage('Certificate background could not be deleted. Please contact support.');
        setShowSnackbar(true);
      });
    }
  

  const onSubmit = (formData) => {
    formData.sections = currentSections;
    formData.quizQuestions = currentQuizQuestions;
    formData.certificate = certificateFormGetValues();
    setWorkInProgress(true);
    createCourse(formData).then(result => {
      setWorkInProgress(false);
      setSaveDisabled(true);
      setSnackbarMessage('Course details were saved successfully.');
      setShowSnackbar(true);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Some error occured while saving details of the Course. Please contact support.');
      setShowSnackbar(true);
    });
  }

  const showList = () => {
    navigate('/home/courses', { replace: false });
  }

  const formFieldStyle = {
    width: '100%',
    marginBottom: 5
  }

  const onDeleteSectionsConfirmation = (confirmation) => {
    if (confirmation) {
      setCurrentSections(currentSections.filter((e, i) => i != expendableObjectId));
      setSnackbarMessage('Sections deleted successfully.');
      setShowSnackbar(true);
    }
    setShowDeleteConfirmation('none');
  }

  const onDeleteQuestionsConfirmation = (confirmation) => {
    console.log(confirmation);
    console.log(expendableQuestionId);
    if (confirmation) {
      setCurrentQuizQuestions(currentQuizQuestions.filter((e, i) => i != expendableQuestionId));
      setSnackbarMessage('Quiz question deleted successfully.');
      setShowSnackbar(true);
    }
    setShowDeleteConfirmation('none');
  }

  const deleteSection = (id) => {
    setExpendableObjectId(id);
    setShowDeleteConfirmation('Section');
  }

  const deleteQuizQuestion = (id) => {
    setExpendableQuestionId(id);
    setShowDeleteConfirmation('Quiz Question');
  }

  const addSection =  (section) => {
    if (section.content === '') {
      section.content = null;
    }
    setCurrentSections([...currentSections, section]);
  }

  const addQuizQuestion = (quizQuestion) => {
    console.log(quizQuestion);
    setCurrentQuizQuestions([...currentQuizQuestions, quizQuestion]);
  }

  useEffect(() => {
    setWorkInProgress(true);
    courseContentGetAll().then(result => {
      setWorkInProgress(false);
      setCourseContents(result.data.data);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Could not fetch related entities for editing. Please contact support.');
      setShowSnackbar(true);
    })
  }, [])

  useEffect(() => {
    setWorkInProgress(true);
    questionGetAll().then(result => {
      setWorkInProgress(false);
      setQuestions(result.data.data);
    }).catch(err => {
      setWorkInProgress(false);
      console.log(err);
      setSnackbarMessage('Could not fetch related entities for editing. Please contact support.');
      setShowSnackbar(true);
    })
  }, [])

  return (
    <div>
      {workInProgress?<Spinner/>:<div></div>}
      <div style={{ display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' }}>
        <h2>New Course</h2>
        {rights.includes('read-course') ? <Fab size="small" color="secondary" aria-label="add" onClick={showList}>
          <ListIcon />
        </Fab>: <span>&nbsp;</span>}
      </div>
      {rights.includes('create-course') ? <div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Course Details" />
              <Tab label="Sections" />
              <Tab label="Quiz Questions" />
              <Tab label="Certificate Details" />
            </Tabs>
          </Box>
          <div role="tabpanel" hidden={tabValue !== 0}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="name"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.name ? true : false} variant="standard" label="Name" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="description"
                    render={({ field }) => <TextField multiline rows="7" style={{ width: '100%' }} error={courseFormErrors.description ? true : false} variant="standard" label="Description" {...field} />}/>
                  </div>  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="introduction"
                    render={({ field }) => <TextField multiline rows="7" style={{ width: '100%' }} error={courseFormErrors.introduction ? true : false} variant="standard" label="Introduction" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="level"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.level ? true : false} variant="standard" label="Level" {...field} />}/>
                  </div>  
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="code"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.code ? true : false} variant="standard" label="Code" {...field} />}/>
                  </div>  
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    
                    name="duration"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.duration ? true : false} variant="standard" label="Duration" {...field} />}/>
                  </div><div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    name="quizQualifyingScore"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.credits ? true : false} variant="standard" label="Qualifying Score" {...field} />}/>
                  </div>  
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    name="credits"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.credits ? true : false} variant="standard" label="Course Credits" {...field} />}/>
                  </div>    
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={courseFormControl}
                    name="hasQuiz"
                    render={({ field }) => <FormControlLabel label="This course has an associated quiz" style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }} control={<Switch {...field} />}></FormControlLabel>}/>
                  </div> 
                </div>
              </form>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 1}>
            <TableContainer sx={{my: 3}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Content</TableCell>
                    <TableCell>Scenario</TableCell>
                    <TableCell style={{width: 80}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentSections.map((section, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{section.content}</TableCell>
                        <TableCell>{section.scenario}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={evt => deleteSection(i)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={sectionsFormControl}
                    rules={{ required: true }}
                    name="content"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Content *</InputLabel><Select id="select-1" labelId="label-1" style={{ width: '100%' }} error={sectionsFormErrors.content ? true : false} variant="standard" label="Content *" {...field}>{courseContents && courseContents.length && courseContents.map((courseContent, i) => <MenuItem key={i} value={courseContent._id}>{courseContent.name + ' ' + courseContent.description}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={sectionsFormControl}
                    name="scenario"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={sectionsFormErrors.scenario ? true : false} variant="standard" label="Scenario" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={sectionsFormControl}
                    name="description"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={sectionsFormErrors.description ? true : false} variant="standard" label="Description" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={sectionsFormControl}
                    name="questions"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-2">Questions</InputLabel><Select multiple id="select-2" labelId="label-2" style={{ width: '100%' }} error={sectionsFormErrors.questions ? true : false} variant="standard" label="Questions" {...field}>{questions && questions.length && questions.map((question, i) => <MenuItem key={i} value={question._id}>{question.questionType + ' ' + question.questionText}</MenuItem>)}</Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={sectionsFormControl}

                      name="percentage"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={courseFormErrors.percentage ? true : false} variant="standard" label="Percentage" {...field} />} />
                  </div>
                </div>
              </form>
              <div style={{marginTop: 20}}>
                <Button variant='contained' color='primary' onClick={sectionsFormHandler(addSection)}>
                  Add Section
                </Button>
              </div>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 2}>
            <TableContainer sx={{my: 3}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: '300px'}}>Type</TableCell>
                    <TableCell>Text</TableCell>
                    <TableCell style={{width: 80}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentQuizQuestions.map((question, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>{question.questionType}</TableCell>
                        <TableCell>{question.questionText}</TableCell>
                        <TableCell>
                          <IconButton color="error" onClick={evt => deleteQuizQuestion(i)}>
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    rules={{ required: true }}
                    name="questionType"
                    render={({ field }) => <FormControl style={{width: '100%'}} variant="standard" sx={{ minWidth: 120 }}><InputLabel id="label-1">Type *</InputLabel><Select id="select-2" labelId="label-2" style={{ width: '100%' }} error={questionFormErrors.questionType ? true : false} variant="standard" label="Type *" {...field}><MenuItem key={0} value='TRUE-FALSE'>True/False</MenuItem><MenuItem key={1} value='SINGLE-ANSWER-MCQ'>Single Answer MCQ</MenuItem></Select></FormControl>}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    name="questionText"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={sectionsFormErrors.questionText ? true : false} variant="standard" label="Question Text" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    name="options"
                    render={({ field }) => <TextField multiline rows="5" style={{ width: '100%' }} error={sectionsFormErrors.options ? true : false} variant="standard" label="Options" {...field} />}/>
                  </div>  
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={questionFormControl}
                    name="correctOption"
                    render={({ field }) => <TextField style={{ width: '100%' }} error={sectionsFormErrors.correctOption ? true : false} variant="standard" label="Correct Option (First option being 0)" {...field} />}/>
                  </div>  
                </div>
              </form>
              <div style={{marginTop: 20}}>
                <Button variant='contained' color='primary' onClick={questionFormHandler(addQuizQuestion)}>
                  Add Question
                </Button>
              </div>
            </Box>
          </div>
          <div role="tabpanel" hidden={tabValue !== 3}>
            <Box sx={{ pt: 3, pb: 3 }}>
              <form>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificate"
                      render={({ field }) => <FormControlLabel label="Certificate" style={{ width: '100%' }} variant="standard" sx={{ minWidth: 120 }} {...field} control={<Switch checked={field.value} {...field} />}></FormControlLabel>} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificateBackground"
                      render={({ field }) => <FileUpload style={{ width: '100%' }} label="Certificate background" handleDelete={handleCertificateBackgroundDelete} handleUpload={handleCertificateBackgroundUpload} {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="resolution"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.resolution ? true : false} variant="standard" label="Resolution widthxheight" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificateLine1"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.certificateLine1 ? true : false} variant="standard" label="Line1" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line1Position"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line1Position ? true : false} variant="standard" label="Line1 Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line1Size"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line1Size ? true : false} variant="standard" label="Line1 Size" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line1Font"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line1Font ? true : false} variant="standard" label="Line1 Font" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificateLine2"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.certificateLine2 ? true : false} variant="standard" label="Line2" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line2Position"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line2Position ? true : false} variant="standard" label="Line2 Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line2Size"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line2Size ? true : false} variant="standard" label="Line2 Size" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line2Font"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line2Font ? true : false} variant="standard" label="Line2 Font" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificateLine3"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.certificateLine3 ? true : false} variant="standard" label="Line3" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line3Position"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line3Position ? true : false} variant="standard" label="Line3 Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line3Size"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line3Size ? true : false} variant="standard" label="Line3 Size" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line3Font"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line3Font ? true : false} variant="standard" label="Line3 Font" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="certificateLine4"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.certificateLine4 ? true : false} variant="standard" label="Line4" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line4Position"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line4Position ? true : false} variant="standard" label="Line4 Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line4Size"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line4Size ? true : false} variant="standard" label="Line4 Size" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="line4Font"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.line4Font ? true : false} variant="standard" label="Line4 Font" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="namePosition"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.namePosition ? true : false} variant="standard" label="Name Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="nameSize"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.nameSize ? true : false} variant="standard" label="Name Size" {...field} />} />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'stretch', gap: 20 }}>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="datePosition"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.datePosition ? true : false} variant="standard" label="Date Position - x,y" {...field} />} />
                  </div>
                  <div style={formFieldStyle}>
                    <Controller control={certificateFormControl}
                      name="dateSize"
                      render={({ field }) => <TextField style={{ width: '100%' }} error={certificateFormErrors.dateSize ? true : false} variant="standard" label="Date Size" {...field} />} />
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Box>
        <div style={{ display: 'flex', justifyContent: 'end', gap: 10, padding: '20 10 0 10' }}>
          <Button variant='contained' color='primary' type='submit' onClick={courseFormHandler(onSubmit)} disabled={saveDisabled}>Save</Button>
          <Button variant='contained' color='error' onClick={showList}>Cancel</Button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSnackbar}
          autoHideDuration={3000}
          message={snackbarMessage}
          action={action}
          onClose={() => setShowSnackbar(false)}
        />
        <Confirmation
          title='Delete confirmation'
          message={'Are you sure you want to delete the Section. Once deleted the data cannot be recovered.'}
          open={showDeleteConfirmation === 'Section'}
          handleClose={onDeleteSectionsConfirmation} />
        <Confirmation
          title='Delete confirmation'
          message={'Are you sure you want to delete the Quiz Question. Once deleted the data cannot be recovered.'}
          open={showDeleteConfirmation === 'Quiz Question'}
          handleClose={onDeleteQuestionsConfirmation} />
      </div>: <span>You are not authorized to view this page</span>}
    </div>
  )
}